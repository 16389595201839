import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Program } from '../../domain/Program';
import { ListResults } from '../../common/List/List';
import { Period } from '../../domain/Period';
import { NightsPeriod } from '../../domain/NightsPeriod';
import { ProgramParams } from './service';

export const fetchProgramsStart = () => ({
  type: actionTypes.FETCH_PROGRAMS_START,
});

export const fetchProgramsSuccess = (programsList: ListResults<Program>) => ({
  type: actionTypes.FETCH_PROGRAMS_SUCCESS,
  programsList,
});

export const fetchProgramsFail = (programsError: HttpError) => ({
  type: actionTypes.FETCH_PROGRAMS_FAIL,
  programsError,
});

export const fetchProgramStart = () => ({
  type: actionTypes.FETCH_PROGRAM_START,
});

export const fetchProgramSuccess = (program: Program) => ({
  type: actionTypes.FETCH_PROGRAM_SUCCESS,
  program,
});

export const fetchProgramFail = (program: HttpError) => ({
  type: actionTypes.FETCH_PROGRAM_FAIL,
  program,
});

export const fetchPublicProgramStart = () => ({
  type: actionTypes.FETCH_PUBLIC_PROGRAM_START,
});

export const fetchPublicProgramSuccess = (
  publicProgram: Program,
  programParams: ProgramParams,
) => ({
  type: actionTypes.FETCH_PUBLIC_PROGRAM_SUCCESS,
  publicProgram: { publicProgram, programParams },
});

export const fetchPublicProgramFail = (publicProgramError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_PROGRAM_FAIL,
  publicProgramError,
});

export const createProgramStart = () => ({
  type: actionTypes.CREATE_PROGRAM_START,
});

export const createProgramSuccess = (createdProgram: Program) => ({
  type: actionTypes.CREATE_PROGRAM_SUCCESS,
  createdProgram,
});

export const createProgramFail = (programCreateError: HttpError) => ({
  type: actionTypes.CREATE_PROGRAM_FAIL,
  programCreateError,
});

export const updateProgramStart = () => ({
  type: actionTypes.UPDATE_PROGRAM_START,
});

export const updateProgramSuccess = () => ({
  type: actionTypes.UPDATE_PROGRAM_SUCCESS,
});

export const updateProgramFail = (programUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_PROGRAM_FAIL,
  programUpdateError,
});

export const deleteProgramStart = () => ({
  type: actionTypes.DELETE_PROGRAM_START,
});

export const deleteProgramSuccess = () => ({
  type: actionTypes.DELETE_PROGRAM_SUCCESS,
});

export const deleteProgramFail = (programDeleteError: HttpError) => ({
  type: actionTypes.DELETE_PROGRAM_FAIL,
  programDeleteError,
});

export const appendProgramPeriods = (period: Period) => ({
  type: actionTypes.APPEND_PROGRAM_PERIODS,
  period,
});

export const appendProgramNightsPeriods = (nightsPeriod: NightsPeriod) => ({
  type: actionTypes.APPEND_PROGRAM_NIGHTS_PERIODS,
  nightsPeriod,
});

export const setProgramSortOrdersStart = () => ({
  type: actionTypes.SET_PROGRAM_SORT_ORDER_START,
});

export const setProgramSortOrdersSuccess = () => ({
  type: actionTypes.SET_PROGRAM_SORT_ORDER_SUCCESS,
});

export const setProgramSortOrdersFail = (
  programSortOrdersError: HttpError,
) => ({
  type: actionTypes.SET_PROGRAM_SORT_ORDER_FAIL,
  programSortOrdersError,
});

export const resetCreatedProgram = () => ({
  type: actionTypes.RESET_CREATED_PROGRAM,
});

export const resetProgramStore = () => ({
  type: actionTypes.RESET_PROGRAM_STORE,
});

export const resetPublicProgramStore = () => ({
  type: actionTypes.RESET_PUBLIC_PROGRAM_STORE,
});
