export const CREATE_PERIOD_START = 'CREATE_PERIOD_START';
export const CREATE_PERIOD_SUCCESS = 'CREATE_PERIOD_SUCCESS';
export const CREATE_PERIOD_FAIL = 'CREATE_PERIOD_FAIL';

export const DUPLICATE_PERIOD_START = 'DUPLICATE_PERIOD_START';
export const DUPLICATE_PERIOD_SUCCESS = 'DUPLICATE_PERIOD_SUCCESS';
export const DUPLICATE_PERIOD_FAIL = 'DUPLICATE_PERIOD_FAIL';

export const UPDATE_PERIOD_START = 'UPDATE_PERIOD_START';
export const UPDATE_PERIOD_SUCCESS = 'UPDATE_PERIOD_SUCCESS';
export const UPDATE_PERIOD_FAIL = 'UPDATE_PERIOD_FAIL';

export const DELETE_PERIOD_START = 'DELETE_PERIOD_START';
export const DELETE_PERIOD_SUCCESS = 'DELETE_PERIOD_SUCCESS';
export const DELETE_PERIOD_FAIL = 'DELETE_PERIOD_FAIL';

export const RESET_CREATED_PERIOD = 'RESET_CREATED_PERIOD';
export const RESET_DUPLICATED_PERIOD = 'RESET_DUPLICATED_PERIOD';
export const RESET_UPDATED_PERIOD = 'RESET_UPDATED_PERIOD';
export const RESET_DELETED_PERIOD = 'RESET_DELETED_PERIOD';

export const RESET_PERIOD_STORE = 'RESET_PERIOD_STORE';

export const LOGOUT = 'LOGOUT';

export type PeriodActionTypes =
  | typeof CREATE_PERIOD_START
  | typeof CREATE_PERIOD_SUCCESS
  | typeof CREATE_PERIOD_FAIL
  | typeof DUPLICATE_PERIOD_START
  | typeof DUPLICATE_PERIOD_SUCCESS
  | typeof DUPLICATE_PERIOD_FAIL
  | typeof UPDATE_PERIOD_START
  | typeof UPDATE_PERIOD_SUCCESS
  | typeof UPDATE_PERIOD_FAIL
  | typeof DELETE_PERIOD_START
  | typeof DELETE_PERIOD_SUCCESS
  | typeof DELETE_PERIOD_FAIL
  | typeof RESET_CREATED_PERIOD
  | typeof RESET_DUPLICATED_PERIOD
  | typeof RESET_UPDATED_PERIOD
  | typeof RESET_DELETED_PERIOD
  | typeof RESET_PERIOD_STORE
  | typeof LOGOUT;
