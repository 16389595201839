import * as actionTypes from './actionTypes';
import { TransferActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Transfer } from '../../domain/Transfer';
import { ListResults } from '../../common/List/List';

export type TransferStateType = {
  transfersList: ListResults<Transfer> | null;
  transfersLoading: boolean;
  transfersError: HttpError;
  contactLoading: boolean;
  contactError: HttpError;
  contactSuccess: boolean;
};

export type TransferActionType = TransferStateType & {
  type: TransferActionTypes;
};

export const initialState: TransferStateType = {
  transfersList: null,
  transfersLoading: true,
  transfersError: null,
  contactLoading: false,
  contactError: null,
  contactSuccess: false,
};

const fetchTransfersStart = (state: TransferStateType): TransferStateType => ({
  ...state,
  transfersLoading: true,
});

const fetchTransfersSuccess = (
  state: TransferStateType,
  action: TransferActionType,
): TransferStateType => ({
  ...state,
  transfersList: action.transfersList,
  transfersLoading: false,
  transfersError: null,
});

const fetchTransfersFail = (
  state: TransferStateType,
  action: TransferActionType,
): TransferStateType => ({
  ...state,
  transfersError: action.transfersError,
  transfersLoading: false,
});

const contactStart = (state: TransferStateType): TransferStateType => ({
  ...state,
  contactLoading: true,
  contactSuccess: false,
});

const contactSuccess = (state: TransferStateType): TransferStateType => ({
  ...state,
  contactLoading: false,
  contactError: null,
  contactSuccess: true,
});

const contactFail = (
  state: TransferStateType,
  action: TransferActionType,
): TransferStateType => ({
  ...state,
  contactError: action.contactError,
  contactLoading: false,
});

const resetTransferStore = (): TransferStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TransferActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSFERS_START:
      return fetchTransfersStart(state);
    case actionTypes.FETCH_TRANSFERS_SUCCESS:
      return fetchTransfersSuccess(state, action);
    case actionTypes.FETCH_TRANSFERS_FAIL:
      return fetchTransfersFail(state, action);
    case actionTypes.CONTACT_START:
      return contactStart(state);
    case actionTypes.CONTACT_SUCCESS:
      return contactSuccess(state);
    case actionTypes.CONTACT_FAIL:
      return contactFail(state, action);
    case actionTypes.RESET_TRANSFER_STORE:
      return resetTransferStore();
    case actionTypes.LOGOUT:
      return resetTransferStore();
    default:
      return state;
  }
};

export default reducer;
