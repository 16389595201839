import * as actionTypes from './actionTypes';
import { LocationGroupActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { LocationGroup, LocationGroupOption } from '../../domain/LocationGroup';
import { ListResults } from '../../common/List/List';

export type LocationGroupStateType = {
  locationGroupsList: ListResults<LocationGroup> | null;
  locationGroupsLoading: boolean;
  locationGroupsError: HttpError;
  locationGroupsListUpdateNeeded: boolean;
  locationGroupCreateLoading: boolean;
  locationGroupCreateError: HttpError;
  locationGroupCreateSuccess: boolean;
  createdLocationGroup: LocationGroup | null;
  locationGroupUpdateLoading: boolean;
  locationGroupUpdateError: HttpError;
  locationGroupUpdateSuccess: boolean;
  locationGroupDeleteLoading: boolean;
  locationGroupDeleteError: HttpError;
  locationGroupDeleteSuccess: boolean;
  locationGroup: LocationGroup | null;
  locationGroupLoading: boolean;
  locationGroupError: HttpError;
  locationGroupPublicOptions: LocationGroupOption[];
  locationGroupPublicOptionsLoading: boolean;
  locationGroupPublicOptionsError: HttpError;
};

export type LocationGroupActionType = LocationGroupStateType & {
  type: LocationGroupActionTypes;
};

export const initialState: LocationGroupStateType = {
  locationGroupsList: null,
  locationGroupsLoading: true,
  locationGroupsError: null,
  locationGroupsListUpdateNeeded: false,
  locationGroupCreateLoading: false,
  locationGroupCreateError: null,
  locationGroupCreateSuccess: false,
  createdLocationGroup: null,
  locationGroupUpdateLoading: false,
  locationGroupUpdateError: null,
  locationGroupUpdateSuccess: false,
  locationGroupDeleteLoading: false,
  locationGroupDeleteError: null,
  locationGroupDeleteSuccess: false,
  locationGroup: null,
  locationGroupLoading: false,
  locationGroupError: null,
  locationGroupPublicOptions: [],
  locationGroupPublicOptionsLoading: true,
  locationGroupPublicOptionsError: null,
};

const fetchLocationGroupsStart = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  locationGroupsLoading: true,
});

const fetchLocationGroupsSuccess = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupsList: action.locationGroupsList,
  locationGroupsLoading: false,
  locationGroupsError: null,
  locationGroupsListUpdateNeeded: false,
});

const fetchLocationGroupsFail = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupsError: action.locationGroupsError,
  locationGroupsLoading: false,
});

const fetchLocationGroupStart = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  locationGroupLoading: true,
  locationGroupCreateSuccess: false,
  locationGroupCreateError: null,
});

const fetchLocationGroupSuccess = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroup: action.locationGroup,
  locationGroupLoading: false,
  locationGroupError: null,
});

const fetchLocationGroupFail = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupError: action.locationGroupError,
  locationGroupLoading: false,
});

const createLocationGroupStart = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  locationGroupCreateLoading: true,
});

const createLocationGroupSuccess = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupCreateLoading: false,
  locationGroupCreateError: null,
  locationGroupCreateSuccess: true,
  createdLocationGroup: action.createdLocationGroup,
});

const createLocationGroupFail = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupCreateLoading: false,
  locationGroupCreateError: action.locationGroupCreateError,
});

const updateLocationGroupStart = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  locationGroupUpdateLoading: true,
  locationGroupUpdateSuccess: false,
});

const updateLocationGroupSuccess = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  locationGroupUpdateLoading: false,
  locationGroupUpdateError: null,
  locationGroupUpdateSuccess: true,
});

const updateLocationGroupFail = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupUpdateLoading: false,
  locationGroupUpdateError: action.locationGroupUpdateError,
});

const deleteLocationGroupStart = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  locationGroupDeleteLoading: true,
});

const deleteLocationGroupSuccess = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  locationGroupDeleteLoading: false,
  locationGroupDeleteError: null,
  locationGroupDeleteSuccess: true,
  locationGroupsListUpdateNeeded: true,
});

const deleteLocationGroupFail = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupDeleteLoading: false,
  locationGroupDeleteError: action.locationGroupDeleteError,
});

const fetchPublicLocationGroupOptionsStart = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  locationGroupPublicOptionsLoading: true,
});

const fetchPublicLocationGroupOptionsSuccess = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupPublicOptions: action.locationGroupPublicOptions,
  locationGroupPublicOptionsLoading: false,
  locationGroupPublicOptionsError: null,
});

const fetchPublicLocationGroupOptionsFail = (
  state: LocationGroupStateType,
  action: LocationGroupActionType,
): LocationGroupStateType => ({
  ...state,
  locationGroupPublicOptionsError: action.locationGroupPublicOptionsError,
  locationGroupPublicOptionsLoading: false,
});

const resetCreatedLocationGroup = (
  state: LocationGroupStateType,
): LocationGroupStateType => ({
  ...state,
  createdLocationGroup: null,
});

const resetLocationGroupStore = (): LocationGroupStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LocationGroupActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATION_GROUPS_START:
      return fetchLocationGroupsStart(state);
    case actionTypes.FETCH_LOCATION_GROUPS_SUCCESS:
      return fetchLocationGroupsSuccess(state, action);
    case actionTypes.FETCH_LOCATION_GROUPS_FAIL:
      return fetchLocationGroupsFail(state, action);
    case actionTypes.FETCH_LOCATION_GROUP_START:
      return fetchLocationGroupStart(state);
    case actionTypes.FETCH_LOCATION_GROUP_SUCCESS:
      return fetchLocationGroupSuccess(state, action);
    case actionTypes.FETCH_LOCATION_GROUP_FAIL:
      return fetchLocationGroupFail(state, action);
    case actionTypes.CREATE_LOCATION_GROUP_START:
      return createLocationGroupStart(state);
    case actionTypes.CREATE_LOCATION_GROUP_SUCCESS:
      return createLocationGroupSuccess(state, action);
    case actionTypes.CREATE_LOCATION_GROUP_FAIL:
      return createLocationGroupFail(state, action);
    case actionTypes.UPDATE_LOCATION_GROUP_START:
      return updateLocationGroupStart(state);
    case actionTypes.UPDATE_LOCATION_GROUP_SUCCESS:
      return updateLocationGroupSuccess(state);
    case actionTypes.UPDATE_LOCATION_GROUP_FAIL:
      return updateLocationGroupFail(state, action);
    case actionTypes.DELETE_LOCATION_GROUP_START:
      return deleteLocationGroupStart(state);
    case actionTypes.DELETE_LOCATION_GROUP_SUCCESS:
      return deleteLocationGroupSuccess(state);
    case actionTypes.DELETE_LOCATION_GROUP_FAIL:
      return deleteLocationGroupFail(state, action);
    case actionTypes.RESET_CREATED_LOCATION_GROUP:
      return resetCreatedLocationGroup(state);
    case actionTypes.FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_START:
      return fetchPublicLocationGroupOptionsStart(state);
    case actionTypes.FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_SUCCESS:
      return fetchPublicLocationGroupOptionsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_FAIL:
      return fetchPublicLocationGroupOptionsFail(state, action);
    case actionTypes.RESET_LOCATION_GROUP_STORE:
      return resetLocationGroupStore();
    case actionTypes.LOGOUT:
      return resetLocationGroupStore();
    default:
      return state;
  }
};

export default reducer;
