import React, { useMemo, useState } from 'react';
import styles from './AccommodationInput.module.scss';
import { Popover } from '@mui/material';
import iconAdult from '../../assets/icons/adult.svg';
import iconChild from '../../assets/icons/child.svg';
import { ReactSVG } from 'react-svg';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';

export type AccommodationModel = {
  adultCount: number;
  children: (number | null)[];
};

type Props = {
  name: string;
  value: AccommodationModel;
  onAccommodationChange?: (name: string, data: AccommodationModel) => void;
  disabled?: boolean;
};

const AccommodationInput = ({
  onAccommodationChange,
  value,
  name,
  disabled,
}: Props) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const getAdultCount = useMemo(() => value?.adultCount ?? 0, [value]);
  const getChildrenCount = useMemo(() => value?.children?.length ?? 0, [value]);
  const options = useMemo(
    () =>
      Array.from(Array(18).keys()).map((val: number) => ({
        label: `${val} ${translate(intl, 'ACCOMMODATION.YEARS_OLD')}`,
        value: val.toString(),
      })),
    [],
  );

  const handleChange = (value: AccommodationModel) => {
    onAccommodationChange && onAccommodationChange(name, value);
  };

  const ACCOMMODATION_INPUTS = [
    {
      icon: iconAdult,
      label: translate(intl, 'ACCOMMODATION.ADULTS'),
      count: getAdultCount,
      isDisabled: !getAdultCount || disabled,
      minCount: 1,
      maxCount: 6,
      onAdd: () => ({
        ...(value as AccommodationModel),
        adultCount: getAdultCount + 1,
      }),
      onRemove: () => ({
        ...(value as AccommodationModel),
        adultCount: getAdultCount - 1,
      }),
    },
    {
      icon: iconChild,
      label: translate(intl, 'ACCOMMODATION.CHILDREN'),
      count: getChildrenCount,
      isDisabled: !getChildrenCount || disabled,
      minCount: 0,
      maxCount: 3,
      onAdd: () => {
        const parsedValue = value as AccommodationModel;

        return {
          ...parsedValue,
          children: [...parsedValue.children, null],
        };
      },
      onRemove: () => {
        const parsedValue = value as AccommodationModel;
        parsedValue.children?.pop();

        return {
          ...parsedValue,
          children: parsedValue.children,
        };
      },
    },
  ];

  const renderAgeSelect = (index: number, age: number | null) => {
    return (
      <TextField
        key={`name-${index}`}
        name={`name-${index}`}
        type="select"
        value={age?.toString() ?? ''}
        onChange={(event) => {
          const ages = [...value.children];

          ages[index] = +event.target.value;

          handleChange({
            ...value,
            children: ages,
          });
        }}
        isSmall
        label={translate(intl, 'ACCOMMODATION.SELECT_AGE')}
        options={options}
      />
    );
  };

  const renderPopoverContent = () => {
    return (
      <div className={styles.popoverContainer}>
        {ACCOMMODATION_INPUTS.map((input) => (
          <div key={input.label} className={styles.popoverValueContainer}>
            <ReactSVG src={input.icon} className={styles.peopleIcon} />
            <div className={styles.controlContainer}>
              <Button
                className={styles.buttonControl}
                buttonVariant="contained"
                color="secondary"
                isDisabled={input.isDisabled || input.minCount === input.count}
                onClick={() => handleChange(input.onRemove())}
              >
                -
              </Button>
              <span className={styles.countContainer}>
                {input.count} {input.label}
              </span>
              <Button
                className={styles.buttonControl}
                buttonVariant="contained"
                color="secondary"
                onClick={() => handleChange(input.onAdd())}
                isDisabled={disabled || input.count === input.maxCount}
              >
                +
              </Button>
            </div>
          </div>
        ))}

        {!!value?.children?.length && (
          <div className={styles.ageSelectContainer}>
            {value.children.map((age, index) => renderAgeSelect(index, age))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={styles.accommodationContainer}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsOpen(true);
        }}
      >
        <div className={styles.valueContainer}>
          <ReactSVG src={iconAdult} className={styles.peopleIcon} />
          <div className={styles.value}>{getAdultCount}</div>
        </div>
        <div className={styles.valueContainer}>
          <ReactSVG src={iconChild} className={styles.peopleIcon} />
          <div className={styles.value}>{getChildrenCount}</div>
        </div>
      </div>
      <Popover
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          setAnchorEl(undefined);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {renderPopoverContent()}
      </Popover>
    </>
  );
};

export default AccommodationInput;
