import React, { ReactNode } from 'react';
import styles from './CurrentAccount.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

type Props = {
  img: string;
  title: string;
  subTitle: string;
  children: ReactNode;
  onClick: () => void;
};

export const CurrentAccount = ({
  img,
  title,
  subTitle,
  children,
  onClick,
}: Props) => {
  return (
    <div className={styles.currentAccountContainer} onClick={onClick}>
      {img ? (
        <img src={img} alt="" />
      ) : (
        <FontAwesomeIcon icon={faCircleQuestion} fixedWidth size="1x" />
      )}
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subTitle}</div>
      </div>
      {children}
    </div>
  );
};

export default CurrentAccount;
