import * as actionTypes from './actionTypes';
import { RoomActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Room } from '../../domain/Room';
import { ListResults } from '../../common/List/List';

export type RoomStateType = {
  roomsList: ListResults<Room> | null;
  roomsLoading: boolean;
  roomsError: HttpError;
  roomsListUpdateNeeded: boolean;
  roomCreateLoading: boolean;
  roomCreateError: HttpError;
  roomCreateSuccess: boolean;
  createdRoom: Room | null;
  roomUpdateLoading: boolean;
  roomUpdateError: HttpError;
  roomUpdateSuccess: boolean;
  roomDeleteLoading: boolean;
  roomDeleteError: HttpError;
  roomDeleteSuccess: boolean;
  room: Room | null;
  roomLoading: boolean;
  roomError: HttpError;
  roomSortOrdersLoading: boolean;
  roomSortOrdersError: HttpError;
  roomSortOrdersSuccess: boolean;
  publicRooms: Room[];
  publicRoomLoading: boolean;
  publicRoomError: HttpError;
};

export type RoomActionType = RoomStateType & {
  type: RoomActionTypes;
  publicRoom: Room;
};

export const initialState: RoomStateType = {
  roomsList: null,
  roomsLoading: true,
  roomsError: null,
  roomsListUpdateNeeded: false,
  roomCreateLoading: false,
  roomCreateError: null,
  roomCreateSuccess: false,
  createdRoom: null,
  roomUpdateLoading: false,
  roomUpdateError: null,
  roomUpdateSuccess: false,
  roomDeleteLoading: false,
  roomDeleteError: null,
  roomDeleteSuccess: false,
  room: null,
  roomLoading: false,
  roomError: null,
  roomSortOrdersLoading: false,
  roomSortOrdersError: null,
  roomSortOrdersSuccess: false,
  publicRooms: [],
  publicRoomLoading: false,
  publicRoomError: null,
};

const fetchRoomsStart = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomsLoading: true,
});

const fetchRoomsSuccess = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  roomsList: action.roomsList,
  roomsLoading: false,
  roomsError: null,
  roomsListUpdateNeeded: false,
});

const fetchRoomsFail = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  roomsError: action.roomsError,
  roomsLoading: false,
});

const fetchRoomStart = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomLoading: true,
  roomCreateSuccess: false,
  roomCreateError: null,
});

const fetchRoomSuccess = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  room: action.room,
  roomLoading: false,
  roomError: null,
});

const fetchRoomFail = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  roomError: action.roomError,
  roomLoading: false,
});

const fetchPublicRoomStart = (state: RoomStateType): RoomStateType => ({
  ...state,
  publicRoomLoading: true,
});

const fetchPublicRoomSuccess = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  publicRooms: [...state.publicRooms, action.publicRoom],
  publicRoomLoading: false,
  publicRoomError: null,
});

const fetchPublicRoomFail = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  publicRoomError: action.publicRoomError,
  publicRoomLoading: false,
});

const createRoomStart = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomCreateLoading: true,
});

const createRoomSuccess = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  roomCreateLoading: false,
  roomCreateError: null,
  roomCreateSuccess: true,
  createdRoom: action.createdRoom,
});

const createRoomFail = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  roomCreateLoading: false,
  roomCreateError: action.roomCreateError,
});

const updateRoomStart = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomUpdateLoading: true,
  roomUpdateSuccess: false,
});

const updateRoomSuccess = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomUpdateLoading: false,
  roomUpdateError: null,
  roomUpdateSuccess: true,
});

const updateRoomFail = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  roomUpdateLoading: false,
  roomUpdateError: action.roomUpdateError,
});

const deleteRoomStart = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomDeleteLoading: true,
});

const deleteRoomSuccess = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomDeleteLoading: false,
  roomDeleteError: null,
  roomDeleteSuccess: true,
  roomsListUpdateNeeded: true,
});

const deleteRoomFail = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  roomDeleteLoading: false,
  roomDeleteError: action.roomDeleteError,
});

const setRoomSortOrdersStart = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomSortOrdersLoading: true,
  roomSortOrdersSuccess: false,
});

const setRoomSortOrdersSuccess = (state: RoomStateType): RoomStateType => ({
  ...state,
  roomSortOrdersLoading: false,
  roomSortOrdersError: null,
  roomsListUpdateNeeded: true,
  roomSortOrdersSuccess: true,
});

const setRoomSortOrdersFail = (
  state: RoomStateType,
  action: RoomActionType,
): RoomStateType => ({
  ...state,
  roomSortOrdersError: action.roomSortOrdersError,
  roomSortOrdersSuccess: false,
  roomSortOrdersLoading: false,
});

const resetCreatedRoom = (state: RoomStateType): RoomStateType => ({
  ...state,
  createdRoom: null,
});

const resetRoomStore = (): RoomStateType => ({
  ...initialState,
});

const completelyResetRoomStore = (): RoomStateType => ({
  ...initialState,
});

const resetPublicRoomStore = (state: RoomStateType): RoomStateType => ({
  ...state,
  publicRooms: [],
  publicRoomLoading: false,
  publicRoomError: null,
});

const reducer = (state = initialState, action: RoomActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ROOMS_START:
      return fetchRoomsStart(state);
    case actionTypes.FETCH_ROOMS_SUCCESS:
      return fetchRoomsSuccess(state, action);
    case actionTypes.FETCH_ROOMS_FAIL:
      return fetchRoomsFail(state, action);
    case actionTypes.FETCH_ROOM_START:
      return fetchRoomStart(state);
    case actionTypes.FETCH_ROOM_SUCCESS:
      return fetchRoomSuccess(state, action);
    case actionTypes.FETCH_ROOM_FAIL:
      return fetchRoomFail(state, action);
    case actionTypes.FETCH_PUBLIC_ROOM_START:
      return fetchPublicRoomStart(state);
    case actionTypes.FETCH_PUBLIC_ROOM_SUCCESS:
      return fetchPublicRoomSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_ROOM_FAIL:
      return fetchPublicRoomFail(state, action);
    case actionTypes.CREATE_ROOM_START:
      return createRoomStart(state);
    case actionTypes.CREATE_ROOM_SUCCESS:
      return createRoomSuccess(state, action);
    case actionTypes.CREATE_ROOM_FAIL:
      return createRoomFail(state, action);
    case actionTypes.UPDATE_ROOM_START:
      return updateRoomStart(state);
    case actionTypes.UPDATE_ROOM_SUCCESS:
      return updateRoomSuccess(state);
    case actionTypes.UPDATE_ROOM_FAIL:
      return updateRoomFail(state, action);
    case actionTypes.DELETE_ROOM_START:
      return deleteRoomStart(state);
    case actionTypes.DELETE_ROOM_SUCCESS:
      return deleteRoomSuccess(state);
    case actionTypes.DELETE_ROOM_FAIL:
      return deleteRoomFail(state, action);
    case actionTypes.SET_ROOM_SORT_ORDER_START:
      return setRoomSortOrdersStart(state);
    case actionTypes.SET_ROOM_SORT_ORDER_SUCCESS:
      return setRoomSortOrdersSuccess(state);
    case actionTypes.SET_ROOM_SORT_ORDER_FAIL:
      return setRoomSortOrdersFail(state, action);
    case actionTypes.RESET_CREATED_ROOM:
      return resetCreatedRoom(state);
    case actionTypes.RESET_ROOM_STORE:
      return resetRoomStore();
    case actionTypes.RESET_PUBLIC_ROOM_STORE:
      return resetPublicRoomStore(state);
    case actionTypes.LOGOUT:
      return completelyResetRoomStore();
    default:
      return state;
  }
};

export default reducer;
