import * as actionTypes from './actionTypes';
import { HighlightActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Highlight } from '../../domain/Highlight';
import { ListResults } from '../../common/List/List';

export type HighlightStateType = {
  highlightsList: ListResults<Highlight> | null;
  highlightsLoading: boolean;
  highlightsError: HttpError;
  highlightsListUpdateNeeded: boolean;
  highlightCreateLoading: boolean;
  highlightCreateError: HttpError;
  highlightCreateSuccess: boolean;
  createdHighlight: Highlight | null;
  highlightUpdateLoading: boolean;
  highlightUpdateError: HttpError;
  highlightUpdateSuccess: boolean;
  highlightDeleteLoading: boolean;
  highlightDeleteError: HttpError;
  highlightDeleteSuccess: boolean;
  highlightSortOrdersLoading: boolean;
  highlightSortOrdersError: HttpError;
  highlightSortOrdersSuccess: boolean;
};

export type HighlightActionType = HighlightStateType & {
  type: HighlightActionTypes;
};

export const initialState: HighlightStateType = {
  highlightsList: null,
  highlightsLoading: true,
  highlightsError: null,
  highlightsListUpdateNeeded: false,
  highlightCreateLoading: false,
  highlightCreateError: null,
  highlightCreateSuccess: false,
  createdHighlight: null,
  highlightUpdateLoading: false,
  highlightUpdateError: null,
  highlightUpdateSuccess: false,
  highlightDeleteLoading: false,
  highlightDeleteError: null,
  highlightDeleteSuccess: false,
  highlightSortOrdersLoading: false,
  highlightSortOrdersError: null,
  highlightSortOrdersSuccess: false,
};

const fetchHighlightsStart = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...state,
  highlightsLoading: true,
});

const fetchHighlightsSuccess = (
  state: HighlightStateType,
  action: HighlightActionType,
): HighlightStateType => ({
  ...state,
  highlightsList: action.highlightsList,
  highlightsLoading: false,
  highlightsError: null,
  highlightsListUpdateNeeded: false,
});

const fetchHighlightsFail = (
  state: HighlightStateType,
  action: HighlightActionType,
): HighlightStateType => ({
  ...state,
  highlightsError: action.highlightsError,
  highlightsLoading: false,
});

const createHighlightStart = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...state,
  highlightCreateLoading: true,
});

const createHighlightSuccess = (
  state: HighlightStateType,
  action: HighlightActionType,
): HighlightStateType => ({
  ...state,
  highlightCreateLoading: false,
  highlightCreateError: null,
  highlightCreateSuccess: true,
  createdHighlight: action.createdHighlight,
  highlightsListUpdateNeeded: true,
});

const createHighlightFail = (
  state: HighlightStateType,
  action: HighlightActionType,
): HighlightStateType => ({
  ...state,
  highlightCreateLoading: false,
  highlightCreateError: action.highlightCreateError,
});

const updateHighlightStart = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...state,
  highlightUpdateLoading: true,
});

const updateHighlightSuccess = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...state,
  highlightUpdateLoading: false,
  highlightUpdateError: null,
  highlightUpdateSuccess: true,
  highlightsListUpdateNeeded: true,
});

const updateHighlightFail = (
  state: HighlightStateType,
  action: HighlightActionType,
): HighlightStateType => ({
  ...state,
  highlightUpdateLoading: false,
  highlightUpdateError: action.highlightUpdateError,
});

const deleteHighlightStart = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...state,
  highlightDeleteLoading: true,
});

const deleteHighlightSuccess = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...state,
  highlightDeleteLoading: false,
  highlightDeleteError: null,
  highlightDeleteSuccess: true,
  highlightsListUpdateNeeded: true,
});

const deleteHighlightFail = (
  state: HighlightStateType,
  action: HighlightActionType,
): HighlightStateType => ({
  ...state,
  highlightDeleteLoading: false,
  highlightDeleteError: action.highlightDeleteError,
});

const setHighlightSortOrdersStart = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...state,
  highlightSortOrdersLoading: true,
  highlightSortOrdersSuccess: false,
});

const setHighlightSortOrdersSuccess = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...state,
  highlightSortOrdersLoading: false,
  highlightSortOrdersError: null,
  highlightsListUpdateNeeded: true,
  highlightSortOrdersSuccess: true,
});

const setHighlightSortOrdersFail = (
  state: HighlightStateType,
  action: HighlightActionType,
): HighlightStateType => ({
  ...state,
  highlightSortOrdersError: action.highlightSortOrdersError,
  highlightSortOrdersSuccess: false,
  highlightSortOrdersLoading: false,
});

const resetHighlightStore = (
  state: HighlightStateType,
): HighlightStateType => ({
  ...initialState,
});

const completelyResetHighlightStore = (): HighlightStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: HighlightActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_HIGHLIGHTS_START:
      return fetchHighlightsStart(state);
    case actionTypes.FETCH_HIGHLIGHTS_SUCCESS:
      return fetchHighlightsSuccess(state, action);
    case actionTypes.FETCH_HIGHLIGHTS_FAIL:
      return fetchHighlightsFail(state, action);
    case actionTypes.CREATE_HIGHLIGHT_START:
      return createHighlightStart(state);
    case actionTypes.CREATE_HIGHLIGHT_SUCCESS:
      return createHighlightSuccess(state, action);
    case actionTypes.CREATE_HIGHLIGHT_FAIL:
      return createHighlightFail(state, action);
    case actionTypes.UPDATE_HIGHLIGHT_START:
      return updateHighlightStart(state);
    case actionTypes.UPDATE_HIGHLIGHT_SUCCESS:
      return updateHighlightSuccess(state);
    case actionTypes.UPDATE_HIGHLIGHT_FAIL:
      return updateHighlightFail(state, action);
    case actionTypes.DELETE_HIGHLIGHT_START:
      return deleteHighlightStart(state);
    case actionTypes.DELETE_HIGHLIGHT_SUCCESS:
      return deleteHighlightSuccess(state);
    case actionTypes.DELETE_HIGHLIGHT_FAIL:
      return deleteHighlightFail(state, action);
    case actionTypes.SET_HIGHLIGHT_SORT_ORDER_START:
      return setHighlightSortOrdersStart(state);
    case actionTypes.SET_HIGHLIGHT_SORT_ORDER_SUCCESS:
      return setHighlightSortOrdersSuccess(state);
    case actionTypes.SET_HIGHLIGHT_SORT_ORDER_FAIL:
      return setHighlightSortOrdersFail(state, action);
    case actionTypes.RESET_HIGHLIGHT_STORE:
      return resetHighlightStore(state);
    case actionTypes.LOGOUT:
      return completelyResetHighlightStore();
    default:
      return state;
  }
};

export default reducer;
