export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

export const LOGOUT = 'LOGOUT';

export type UserActionTypes =
  | typeof FETCH_ME_START
  | typeof FETCH_ME_SUCCESS
  | typeof FETCH_ME_FAIL
  | typeof LOGOUT;
