import React from 'react';
import styles from './PublicNavigation.module.scss';
import { IconButton } from '@mui/material';
import {
  faBars,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/logo.svg';
import logoSmall from '../../assets/logo.png';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import Button from '../Button/Button';
import cx from 'classnames';
import { TopNavigationItem } from '../Layout/Layout';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { getLocaleUrl } from '../../utility/url/urlHelper';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { Locale } from '../../domain/Translation';
import { connect } from 'react-redux';
import SocialContacts from '../SocialContacts/SocialContacts';
import { LocationsSearchParams } from '../../store/location/service';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { setLocationsSearchParams } from '../../store/location/actions';
import MobilePublicNavigation from './MobilePublicNavigation/MobilePublicNavigation';

export type Props = {
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
  navigationItems: TopNavigationItem[];
  selectedLocale: Locale;
  onSetLocationsSearchParams: (
    searchLocationsParams: LocationsSearchParams,
  ) => void;
  searchLocationsParams: LocationsSearchParams | null;
};

const MOBILE_BREAK_POINT = 900;

export const PublicNavigation = ({
  onDrawerClick,
  isMobileMenuOpen,
  navigationItems,
  selectedLocale,
  onSetLocationsSearchParams,
  searchLocationsParams,
}: Props) => {
  const { width } = useWindowSize();
  const intl = useIntl();
  const navigate = useNavigate();

  const handleSearchClick = () => {
    onSetLocationsSearchParams({
      ...searchLocationsParams,
      locationGroupId: undefined,
    });
    navigate(getLocaleUrl(routes.search, selectedLocale));
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'Search Click',
      eventProps: {
        category: 'Search Button Top Navigation',
        action: 'Click',
      },
    });
  };

  const handleNavigationLinkClick = (label: string) => {
    onSetLocationsSearchParams({
      ...searchLocationsParams,
    });
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'Navigation Link Click',
      eventProps: {
        category: 'Link Top Navigation',
        action: 'Click',
        label: label,
      },
    });
  };

  const getActions = () => {
    return (
      <>
        <div className={styles.actionContainer}>
          {width && width >= MOBILE_BREAK_POINT && (
            <Button className={styles.search} onClick={handleSearchClick}>
              {translate(intl, 'HEADER.SEARCH')}
            </Button>
          )}
          <SocialContacts />
        </div>
      </>
    );
  };

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.logo}>
          <Link to={getLocaleUrl(routes.homepage, selectedLocale)}>
            {width && width >= MOBILE_BREAK_POINT ? (
              <ReactSVG src={logo} />
            ) : (
              <img src={logoSmall} alt="Kurort.lt" />
            )}
          </Link>
        </div>
        <>
          {width && width >= MOBILE_BREAK_POINT && (
            <div className={styles.navigationContainer}>
              <ul className={styles.navigationMenu}>
                {navigationItems.map((item) => (
                  <li key={item.label}>
                    <Link
                      to={item.to}
                      onClick={() => handleNavigationLinkClick(item.label)}
                    >
                      {item.label}
                    </Link>

                    {item.locationGroups && (
                      <ul className={styles.subMenu}>
                        {item.locationGroups.map((locationGroup) => (
                          <li key={`${locationGroup.label}-${item.label}`}>
                            <Link
                              to={locationGroup.to}
                              onClick={() =>
                                handleNavigationLinkClick(locationGroup.label)
                              }
                            >
                              {locationGroup.label}
                              <FontAwesomeIcon
                                className={styles.rightIcon}
                                icon={faChevronRight}
                              />
                            </Link>

                            {locationGroup.locations && (
                              <ul className={styles.subMenuSecond}>
                                {locationGroup.locations.map((location) => (
                                  <li key={location.label}>
                                    <Link
                                      to={location.to}
                                      onClick={() =>
                                        handleNavigationLinkClick(
                                          location.label,
                                        )
                                      }
                                    >
                                      {location.label}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}

                        {item.locationsWithoutGroup &&
                          item.locationsWithoutGroup.map((location) => (
                            <li key={`${location.label}-${item.label}`}>
                              <Link
                                to={location.to}
                                onClick={() =>
                                  handleNavigationLinkClick(location.label)
                                }
                              >
                                {location.label}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {getActions()}
        </>
        <div className={styles.iconItems}>
          <LanguageSwitcher />
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                className={styles.drawerIcon}
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
        </div>
        {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
          <div className={styles.mobileMenu}>
            {navigationItems.map((item, index) => (
              <React.Fragment key={`MobileNavigationItem-${index}`}>
                {item?.locationGroups?.length ||
                item?.locationsWithoutGroup?.length ? (
                  <MobilePublicNavigation
                    topNavigationItem={item}
                    handleNavigationLinkClick={handleNavigationLinkClick}
                  />
                ) : (
                  <NavLink
                    key={item.label}
                    to={item.to}
                    className={({ isActive }) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                    onClick={() => handleNavigationLinkClick(item.label)}
                  >
                    {item.label}
                  </NavLink>
                )}
              </React.Fragment>
            ))}

            <div className={styles.actionContainer}>
              <Button className={styles.search} onClick={handleSearchClick}>
                {translate(intl, 'HEADER.SEARCH')}
              </Button>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  searchLocationsParams: state.location.searchLocationsParams,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetLocationsSearchParams: (searchLocationsParams: LocationsSearchParams) =>
    dispatch(setLocationsSearchParams(searchLocationsParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicNavigation);
