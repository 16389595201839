import axios from '../../config/Axios/axios-instance';
import {
  fetchCurrentUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
} from './actions';
import { Dispatch } from 'redux';

const API_URL = '/users';

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());

  return axios
    .get(`${API_URL}/me`)
    .then((response) => {
      dispatch(fetchCurrentUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
    });
};
