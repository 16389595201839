import { HttpError } from '../../config/Axios/axios-instance';
import _ from 'lodash';

export const getGlobalError = (error: HttpError) => {
  if (_.isArray(error)) {
    return null;
  }

  return error?.toString();
};
