export const FETCH_HIGHLIGHTS_START = 'FETCH_HIGHLIGHTS_START';
export const FETCH_HIGHLIGHTS_SUCCESS = 'FETCH_HIGHLIGHTS_SUCCESS';
export const FETCH_HIGHLIGHTS_FAIL = 'FETCH_HIGHLIGHTS_FAIL';

export const CREATE_HIGHLIGHT_START = 'CREATE_HIGHLIGHT_START';
export const CREATE_HIGHLIGHT_SUCCESS = 'CREATE_HIGHLIGHT_SUCCESS';
export const CREATE_HIGHLIGHT_FAIL = 'CREATE_HIGHLIGHT_FAIL';

export const UPDATE_HIGHLIGHT_START = 'UPDATE_HIGHLIGHT_START';
export const UPDATE_HIGHLIGHT_SUCCESS = 'UPDATE_HIGHLIGHT_SUCCESS';
export const UPDATE_HIGHLIGHT_FAIL = 'UPDATE_HIGHLIGHT_FAIL';

export const SET_HIGHLIGHT_SORT_ORDER_START = 'SET_HIGHLIGHT_SORT_ORDER_START';
export const SET_HIGHLIGHT_SORT_ORDER_SUCCESS =
  'SET_HIGHLIGHT_SORT_ORDER_SUCCESS';
export const SET_HIGHLIGHT_SORT_ORDER_FAIL = 'SET_HIGHLIGHT_SORT_ORDER_FAIL';

export const DELETE_HIGHLIGHT_START = 'DELETE_HIGHLIGHT_START';
export const DELETE_HIGHLIGHT_SUCCESS = 'DELETE_HIGHLIGHT_SUCCESS';
export const DELETE_HIGHLIGHT_FAIL = 'DELETE_HIGHLIGHT_FAIL';

export const RESET_HIGHLIGHT_STORE = 'RESET_HIGHLIGHT_STORE';

export const LOGOUT = 'LOGOUT';

export type HighlightActionTypes =
  | typeof FETCH_HIGHLIGHTS_START
  | typeof FETCH_HIGHLIGHTS_SUCCESS
  | typeof FETCH_HIGHLIGHTS_FAIL
  | typeof CREATE_HIGHLIGHT_START
  | typeof CREATE_HIGHLIGHT_SUCCESS
  | typeof CREATE_HIGHLIGHT_FAIL
  | typeof UPDATE_HIGHLIGHT_START
  | typeof UPDATE_HIGHLIGHT_SUCCESS
  | typeof UPDATE_HIGHLIGHT_FAIL
  | typeof DELETE_HIGHLIGHT_START
  | typeof DELETE_HIGHLIGHT_SUCCESS
  | typeof DELETE_HIGHLIGHT_FAIL
  | typeof SET_HIGHLIGHT_SORT_ORDER_START
  | typeof SET_HIGHLIGHT_SORT_ORDER_SUCCESS
  | typeof SET_HIGHLIGHT_SORT_ORDER_FAIL
  | typeof RESET_HIGHLIGHT_STORE
  | typeof LOGOUT;
