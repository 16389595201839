import * as actionTypes from './actionTypes';
import { EnquiryActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Enquiry } from '../../domain/Enquiry';
import { ListResults } from '../../common/List/List';

export type EnquiryStateType = {
  enquiriesList: ListResults<Enquiry> | null;
  enquiriesLoading: boolean;
  enquiriesError: HttpError;
  enquiriesListUpdateNeeded: boolean;
  enquiryCreateLoading: boolean;
  enquiryCreateError: HttpError;
  enquiryCreateSuccess: boolean;
  createdEnquiry: Enquiry | null;
  enquiryUpdateLoading: boolean;
  enquiryUpdateError: HttpError;
  enquiryUpdateSuccess: boolean;
  enquiryDeleteLoading: boolean;
  enquiryDeleteError: HttpError;
  enquiryDeleteSuccess: boolean;
  enquiry: Enquiry | null;
  enquiryLoading: boolean;
  enquiryError: HttpError;
};

export type EnquiryActionType = EnquiryStateType & {
  type: EnquiryActionTypes;
};

export const initialState: EnquiryStateType = {
  enquiriesList: null,
  enquiriesLoading: true,
  enquiriesError: null,
  enquiriesListUpdateNeeded: false,
  enquiryCreateLoading: false,
  enquiryCreateError: null,
  enquiryCreateSuccess: false,
  createdEnquiry: null,
  enquiryUpdateLoading: false,
  enquiryUpdateError: null,
  enquiryUpdateSuccess: false,
  enquiryDeleteLoading: false,
  enquiryDeleteError: null,
  enquiryDeleteSuccess: false,
  enquiry: null,
  enquiryLoading: false,
  enquiryError: null,
};

const fetchEnquiriesStart = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiriesLoading: true,
});

const fetchEnquiriesSuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiriesList: action.enquiriesList,
  enquiriesLoading: false,
  enquiriesError: null,
  enquiriesListUpdateNeeded: false,
});

const fetchEnquiriesFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiriesError: action.enquiriesError,
  enquiriesLoading: false,
});

const fetchEnquiryStart = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiryLoading: true,
  enquiryCreateSuccess: false,
  enquiryCreateError: null,
});

const fetchEnquirySuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiry: action.enquiry,
  enquiryLoading: false,
  enquiryError: null,
});

const fetchEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiryError: action.enquiryError,
  enquiryLoading: false,
});

const createEnquiryStart = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiryCreateLoading: true,
});

const createEnquirySuccess = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiryCreateLoading: false,
  enquiryCreateError: null,
  enquiryCreateSuccess: true,
  createdEnquiry: action.createdEnquiry,
});

const createEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiryCreateLoading: false,
  enquiryCreateError: action.enquiryCreateError,
});

const updateEnquiryStart = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiryUpdateLoading: true,
  enquiryUpdateSuccess: false,
});

const updateEnquirySuccess = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiryUpdateLoading: false,
  enquiryUpdateError: null,
  enquiryUpdateSuccess: true,
});

const updateEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiryUpdateLoading: false,
  enquiryUpdateError: action.enquiryUpdateError,
});

const deleteEnquiryStart = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiryDeleteLoading: true,
});

const deleteEnquirySuccess = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  enquiryDeleteLoading: false,
  enquiryDeleteError: null,
  enquiryDeleteSuccess: true,
  enquiriesListUpdateNeeded: true,
});

const deleteEnquiryFail = (
  state: EnquiryStateType,
  action: EnquiryActionType,
): EnquiryStateType => ({
  ...state,
  enquiryDeleteLoading: false,
  enquiryDeleteError: action.enquiryDeleteError,
});

const resetCreatedEnquiry = (state: EnquiryStateType): EnquiryStateType => ({
  ...state,
  createdEnquiry: null,
  enquiryCreateSuccess: false,
});

const resetEnquiryStore = (): EnquiryStateType => ({
  ...initialState,
});

const completelyResetEnquiryStore = (): EnquiryStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EnquiryActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ENQUIRIES_START:
      return fetchEnquiriesStart(state);
    case actionTypes.FETCH_ENQUIRIES_SUCCESS:
      return fetchEnquiriesSuccess(state, action);
    case actionTypes.FETCH_ENQUIRIES_FAIL:
      return fetchEnquiriesFail(state, action);
    case actionTypes.FETCH_ENQUIRY_START:
      return fetchEnquiryStart(state);
    case actionTypes.FETCH_ENQUIRY_SUCCESS:
      return fetchEnquirySuccess(state, action);
    case actionTypes.FETCH_ENQUIRY_FAIL:
      return fetchEnquiryFail(state, action);
    case actionTypes.CREATE_ENQUIRY_START:
      return createEnquiryStart(state);
    case actionTypes.CREATE_ENQUIRY_SUCCESS:
      return createEnquirySuccess(state, action);
    case actionTypes.CREATE_ENQUIRY_FAIL:
      return createEnquiryFail(state, action);
    case actionTypes.UPDATE_ENQUIRY_START:
      return updateEnquiryStart(state);
    case actionTypes.UPDATE_ENQUIRY_SUCCESS:
      return updateEnquirySuccess(state);
    case actionTypes.UPDATE_ENQUIRY_FAIL:
      return updateEnquiryFail(state, action);
    case actionTypes.DELETE_ENQUIRY_START:
      return deleteEnquiryStart(state);
    case actionTypes.DELETE_ENQUIRY_SUCCESS:
      return deleteEnquirySuccess(state);
    case actionTypes.DELETE_ENQUIRY_FAIL:
      return deleteEnquiryFail(state, action);
    case actionTypes.RESET_CREATED_ENQUIRY:
      return resetCreatedEnquiry(state);
    case actionTypes.RESET_ENQUIRY_STORE:
      return resetEnquiryStore();
    case actionTypes.LOGOUT:
      return completelyResetEnquiryStore();
    default:
      return state;
  }
};

export default reducer;
