import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { City, CityOption } from '../../domain/City';
import { PublicTopNavigationItem } from '../../domain/PublicTopNavigationItem';
import { ListResults } from '../../common/List/List';

export const fetchCitiesStart = () => ({
  type: actionTypes.FETCH_CITIES_START,
});

export const fetchCitiesSuccess = (citiesList: ListResults<City>) => ({
  type: actionTypes.FETCH_CITIES_SUCCESS,
  citiesList,
});

export const fetchCitiesFail = (citiesError: HttpError) => ({
  type: actionTypes.FETCH_CITIES_FAIL,
  citiesError,
});

export const fetchCityStart = () => ({
  type: actionTypes.FETCH_CITY_START,
});

export const fetchCitySuccess = (city: City) => ({
  type: actionTypes.FETCH_CITY_SUCCESS,
  city,
});

export const fetchCityFail = (city: HttpError) => ({
  type: actionTypes.FETCH_CITY_FAIL,
  city,
});

export const createCityStart = () => ({
  type: actionTypes.CREATE_CITY_START,
});

export const createCitySuccess = (createdCity: City) => ({
  type: actionTypes.CREATE_CITY_SUCCESS,
  createdCity,
});

export const createCityFail = (cityCreateError: HttpError) => ({
  type: actionTypes.CREATE_CITY_FAIL,
  cityCreateError,
});

export const updateCityStart = () => ({
  type: actionTypes.UPDATE_CITY_START,
});

export const updateCitySuccess = () => ({
  type: actionTypes.UPDATE_CITY_SUCCESS,
});

export const updateCityFail = (cityUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_CITY_FAIL,
  cityUpdateError,
});

export const deleteCityStart = () => ({
  type: actionTypes.DELETE_CITY_START,
});

export const deleteCitySuccess = () => ({
  type: actionTypes.DELETE_CITY_SUCCESS,
});

export const deleteCityFail = (cityDeleteError: HttpError) => ({
  type: actionTypes.DELETE_CITY_FAIL,
  cityDeleteError,
});

export const resetCreatedCity = () => ({
  type: actionTypes.RESET_CREATED_CITY,
});

export const resetCityStore = () => ({
  type: actionTypes.RESET_CITY_STORE,
});

export const fetchPublicCityOptionsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_CITY_OPTIONS_START,
});

export const fetchPublicCityOptionsSuccess = (
  cityPublicOptions: CityOption[],
) => ({
  type: actionTypes.FETCH_PUBLIC_CITY_OPTIONS_SUCCESS,
  cityPublicOptions,
});

export const fetchPublicCityOptionsFail = (
  cityPublicOptionsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_CITY_OPTIONS_FAIL,
  cityPublicOptionsError,
});

export const fetchPublicTopNavigationItemsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_TOP_NAVIGATION_ITEMS_START,
});

export const fetchPublicTopNavigationItemsSuccess = (
  publicTopNavigationItems: PublicTopNavigationItem[],
) => ({
  type: actionTypes.FETCH_PUBLIC_TOP_NAVIGATION_ITEMS_SUCCESS,
  publicTopNavigationItems,
});

export const fetchPublicTopNavigationItemsFail = (
  publicTopNavigationItemsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_TOP_NAVIGATION_ITEMS_FAIL,
  publicTopNavigationItemsError,
});
