import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { LocationGroup, LocationGroupOption } from '../../domain/LocationGroup';
import { ListResults } from '../../common/List/List';

export const fetchLocationGroupsStart = () => ({
  type: actionTypes.FETCH_LOCATION_GROUPS_START,
});

export const fetchLocationGroupsSuccess = (
  locationGroupsList: ListResults<LocationGroup>,
) => ({
  type: actionTypes.FETCH_LOCATION_GROUPS_SUCCESS,
  locationGroupsList,
});

export const fetchLocationGroupsFail = (locationGroupsError: HttpError) => ({
  type: actionTypes.FETCH_LOCATION_GROUPS_FAIL,
  locationGroupsError,
});

export const fetchLocationGroupStart = () => ({
  type: actionTypes.FETCH_LOCATION_GROUP_START,
});

export const fetchLocationGroupSuccess = (locationGroup: LocationGroup) => ({
  type: actionTypes.FETCH_LOCATION_GROUP_SUCCESS,
  locationGroup,
});

export const fetchLocationGroupFail = (locationGroup: HttpError) => ({
  type: actionTypes.FETCH_LOCATION_GROUP_FAIL,
  locationGroup,
});

export const createLocationGroupStart = () => ({
  type: actionTypes.CREATE_LOCATION_GROUP_START,
});

export const createLocationGroupSuccess = (
  createdLocationGroup: LocationGroup,
) => ({
  type: actionTypes.CREATE_LOCATION_GROUP_SUCCESS,
  createdLocationGroup,
});

export const createLocationGroupFail = (
  locationGroupCreateError: HttpError,
) => ({
  type: actionTypes.CREATE_LOCATION_GROUP_FAIL,
  locationGroupCreateError,
});

export const updateLocationGroupStart = () => ({
  type: actionTypes.UPDATE_LOCATION_GROUP_START,
});

export const updateLocationGroupSuccess = () => ({
  type: actionTypes.UPDATE_LOCATION_GROUP_SUCCESS,
});

export const updateLocationGroupFail = (
  locationGroupUpdateError: HttpError,
) => ({
  type: actionTypes.UPDATE_LOCATION_GROUP_FAIL,
  locationGroupUpdateError,
});

export const deleteLocationGroupStart = () => ({
  type: actionTypes.DELETE_LOCATION_GROUP_START,
});

export const deleteLocationGroupSuccess = () => ({
  type: actionTypes.DELETE_LOCATION_GROUP_SUCCESS,
});

export const deleteLocationGroupFail = (
  locationGroupDeleteError: HttpError,
) => ({
  type: actionTypes.DELETE_LOCATION_GROUP_FAIL,
  locationGroupDeleteError,
});

export const resetCreatedLocationGroup = () => ({
  type: actionTypes.RESET_CREATED_LOCATION_GROUP,
});

export const resetLocationGroupStore = () => ({
  type: actionTypes.RESET_LOCATION_GROUP_STORE,
});

export const fetchPublicLocationGroupOptionsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_START,
});

export const fetchPublicLocationGroupOptionsSuccess = (
  locationGroupPublicOptions: LocationGroupOption[],
) => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_SUCCESS,
  locationGroupPublicOptions,
});

export const fetchPublicLocationGroupOptionsFail = (
  locationGroupPublicOptionsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_FAIL,
  locationGroupPublicOptionsError,
});
