export const CREATE_NIGHTS_PERIOD_START = 'CREATE_NIGHTS_PERIOD_START';
export const CREATE_NIGHTS_PERIOD_SUCCESS = 'CREATE_NIGHTS_PERIOD_SUCCESS';
export const CREATE_NIGHTS_PERIOD_FAIL = 'CREATE_NIGHTS_PERIOD_FAIL';

export const UPDATE_NIGHTS_PERIOD_START = 'UPDATE_NIGHTS_PERIOD_START';
export const UPDATE_NIGHTS_PERIOD_SUCCESS = 'UPDATE_NIGHTS_PERIOD_SUCCESS';
export const UPDATE_NIGHTS_PERIOD_FAIL = 'UPDATE_NIGHTS_PERIOD_FAIL';

export const DELETE_NIGHTS_PERIOD_START = 'DELETE_NIGHTS_PERIOD_START';
export const DELETE_NIGHTS_PERIOD_SUCCESS = 'DELETE_NIGHTS_PERIOD_SUCCESS';
export const DELETE_NIGHTS_PERIOD_FAIL = 'DELETE_NIGHTS_PERIOD_FAIL';

export const RESET_CREATED_NIGHTS_PERIOD = 'RESET_CREATED_NIGHTS_PERIOD';
export const RESET_UPDATED_NIGHTS_PERIOD = 'RESET_UPDATED_NIGHTS_PERIOD';
export const RESET_DELETED_NIGHTS_PERIOD = 'RESET_DELETED_NIGHTS_PERIOD';

export const RESET_NIGHTS_PERIOD_STORE = 'RESET_NIGHTS_PERIOD_STORE';

export const LOGOUT = 'LOGOUT';

export type NightsPeriodActionTypes =
  | typeof CREATE_NIGHTS_PERIOD_START
  | typeof CREATE_NIGHTS_PERIOD_SUCCESS
  | typeof CREATE_NIGHTS_PERIOD_FAIL
  | typeof UPDATE_NIGHTS_PERIOD_START
  | typeof UPDATE_NIGHTS_PERIOD_SUCCESS
  | typeof UPDATE_NIGHTS_PERIOD_FAIL
  | typeof DELETE_NIGHTS_PERIOD_START
  | typeof DELETE_NIGHTS_PERIOD_SUCCESS
  | typeof DELETE_NIGHTS_PERIOD_FAIL
  | typeof RESET_CREATED_NIGHTS_PERIOD
  | typeof RESET_UPDATED_NIGHTS_PERIOD
  | typeof RESET_DELETED_NIGHTS_PERIOD
  | typeof RESET_NIGHTS_PERIOD_STORE
  | typeof LOGOUT;
