import * as actionTypes from './actionTypes';
import { ProgramDiscountActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ProgramDiscount } from '../../domain/ProgramDiscount';

export type ProgramDiscountStateType = {
  setProgramDiscountLoading: boolean;
  setProgramDiscountError: HttpError;
  setProgramDiscountSuccess: boolean;
  setProgramDiscount: ProgramDiscount | null;
};

export type ProgramDiscountActionType = ProgramDiscountStateType & {
  type: ProgramDiscountActionTypes;
};

export const initialState: ProgramDiscountStateType = {
  setProgramDiscountLoading: false,
  setProgramDiscountError: null,
  setProgramDiscountSuccess: false,
  setProgramDiscount: null,
};

const setProgramDiscountStart = (
  state: ProgramDiscountStateType,
): ProgramDiscountStateType => ({
  ...state,
  setProgramDiscountLoading: true,
});

const setProgramDiscountSuccess = (
  state: ProgramDiscountStateType,
  action: ProgramDiscountActionType,
): ProgramDiscountStateType => ({
  ...state,
  setProgramDiscountLoading: false,
  setProgramDiscountError: null,
  setProgramDiscountSuccess: true,
  setProgramDiscount: action.setProgramDiscount,
});

const setProgramDiscountFail = (
  state: ProgramDiscountStateType,
  action: ProgramDiscountActionType,
): ProgramDiscountStateType => ({
  ...state,
  setProgramDiscountLoading: false,
  setProgramDiscountError: action.setProgramDiscountError,
});

const resetSetProgramDiscount = (
  state: ProgramDiscountStateType,
): ProgramDiscountStateType => ({
  ...state,
  setProgramDiscount: null,
});

const resetProgramDiscountStore = (
  state: ProgramDiscountStateType,
): ProgramDiscountStateType => ({
  ...initialState,
});

const completelyResetProgramDiscountStore = (): ProgramDiscountStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProgramDiscountActionType) => {
  switch (action.type) {
    case actionTypes.SET_PROGRAM_DISCOUNT_START:
      return setProgramDiscountStart(state);
    case actionTypes.SET_PROGRAM_DISCOUNT_SUCCESS:
      return setProgramDiscountSuccess(state, action);
    case actionTypes.SET_PROGRAM_DISCOUNT_FAIL:
      return setProgramDiscountFail(state, action);
    case actionTypes.RESET_SET_PROGRAM_DISCOUNT:
      return resetSetProgramDiscount(state);
    case actionTypes.RESET_PROGRAM_DISCOUNT_STORE:
      return resetProgramDiscountStore(state);
    case actionTypes.LOGOUT:
      return completelyResetProgramDiscountStore();
    default:
      return state;
  }
};

export default reducer;
