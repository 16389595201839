export const FETCH_LOCATION_DISCOUNTS_START = 'FETCH_LOCATION_DISCOUNTS_START';
export const FETCH_LOCATION_DISCOUNTS_SUCCESS =
  'FETCH_LOCATION_DISCOUNTS_SUCCESS';
export const FETCH_LOCATION_DISCOUNTS_FAIL = 'FETCH_LOCATION_DISCOUNTS_FAIL';

export const CREATE_LOCATION_DISCOUNT_START = 'CREATE_LOCATION_DISCOUNT_START';
export const CREATE_LOCATION_DISCOUNT_SUCCESS =
  'CREATE_LOCATION_DISCOUNT_SUCCESS';
export const CREATE_LOCATION_DISCOUNT_FAIL = 'CREATE_LOCATION_DISCOUNT_FAIL';

export const UPDATE_LOCATION_DISCOUNT_START = 'UPDATE_LOCATION_DISCOUNT_START';
export const UPDATE_LOCATION_DISCOUNT_SUCCESS =
  'UPDATE_LOCATION_DISCOUNT_SUCCESS';
export const UPDATE_LOCATION_DISCOUNT_FAIL = 'UPDATE_LOCATION_DISCOUNT_FAIL';

export const DELETE_LOCATION_DISCOUNT_START = 'DELETE_LOCATION_DISCOUNT_START';
export const DELETE_LOCATION_DISCOUNT_SUCCESS =
  'DELETE_LOCATION_DISCOUNT_SUCCESS';
export const DELETE_LOCATION_DISCOUNT_FAIL = 'DELETE_LOCATION_DISCOUNT_FAIL';

export const RESET_LOCATION_DISCOUNT_STORE = 'RESET_LOCATION_DISCOUNT_STORE';

export const LOGOUT = 'LOGOUT';

export type LocationDiscountActionTypes =
  | typeof FETCH_LOCATION_DISCOUNTS_START
  | typeof FETCH_LOCATION_DISCOUNTS_SUCCESS
  | typeof FETCH_LOCATION_DISCOUNTS_FAIL
  | typeof CREATE_LOCATION_DISCOUNT_START
  | typeof CREATE_LOCATION_DISCOUNT_SUCCESS
  | typeof CREATE_LOCATION_DISCOUNT_FAIL
  | typeof UPDATE_LOCATION_DISCOUNT_START
  | typeof UPDATE_LOCATION_DISCOUNT_SUCCESS
  | typeof UPDATE_LOCATION_DISCOUNT_FAIL
  | typeof DELETE_LOCATION_DISCOUNT_START
  | typeof DELETE_LOCATION_DISCOUNT_SUCCESS
  | typeof DELETE_LOCATION_DISCOUNT_FAIL
  | typeof RESET_LOCATION_DISCOUNT_STORE
  | typeof LOGOUT;
