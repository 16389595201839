import * as actionTypes from './actionTypes';
import { Locale } from '../../domain/Translation';
import { EnquiryRequest } from '../../domain/Enquiry';

export const loginGoogleStart = () => ({
  type: actionTypes.LOGIN_GOOGLE_START,
});

export const loginGoogleSuccess = (jwtToken: string) => ({
  type: actionTypes.LOGIN_GOOGLE_SUCCESS,
  jwtToken,
});

export const loginGoogleFail = (loginGoogleError: string) => ({
  type: actionTypes.LOGIN_GOOGLE_FAIL,
  loginGoogleError,
});

export const refreshTokenStart = () => ({
  type: actionTypes.REFRESH_TOKEN_START,
});

export const refreshTokenSuccess = (jwtToken: string) => ({
  type: actionTypes.REFRESH_TOKEN_SUCCESS,
  jwtToken,
});

export const refreshTokenFail = (refreshTokenError: string) => ({
  type: actionTypes.REFRESH_TOKEN_FAIL,
  refreshTokenError,
});

export const selectLocale = (selectedLocale: Locale) => ({
  type: actionTypes.SELECT_LOCALE,
  selectedLocale,
});

export const createEnquiryRequest = (enquiryRequest: EnquiryRequest) => ({
  type: actionTypes.CREATE_ENQUIRY_REQUEST,
  enquiryRequest,
});

export const cancelEnquiryRequest = () => ({
  type: actionTypes.CANCEL_ENQUIRY_REQUEST,
});

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('selectedLocation');
  return {
    type: actionTypes.LOGOUT,
  };
};
