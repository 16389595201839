export const FETCH_ROOMS_START = 'FETCH_ROOMS_START';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
export const FETCH_ROOMS_FAIL = 'FETCH_ROOMS_FAIL';

export const FETCH_ROOM_START = 'FETCH_ROOM_START';
export const FETCH_ROOM_SUCCESS = 'FETCH_ROOM_SUCCESS';
export const FETCH_ROOM_FAIL = 'FETCH_ROOM_FAIL';

export const FETCH_PUBLIC_ROOM_START = 'FETCH_PUBLIC_ROOM_START';
export const FETCH_PUBLIC_ROOM_SUCCESS = 'FETCH_PUBLIC_ROOM_SUCCESS';
export const FETCH_PUBLIC_ROOM_FAIL = 'FETCH_PUBLIC_ROOM_FAIL';

export const CREATE_ROOM_START = 'CREATE_ROOM_START';
export const CREATE_ROOM_SUCCESS = 'CREATE_ROOM_SUCCESS';
export const CREATE_ROOM_FAIL = 'CREATE_ROOM_FAIL';

export const UPDATE_ROOM_START = 'UPDATE_ROOM_START';
export const UPDATE_ROOM_SUCCESS = 'UPDATE_ROOM_SUCCESS';
export const UPDATE_ROOM_FAIL = 'UPDATE_ROOM_FAIL';

export const DELETE_ROOM_START = 'DELETE_ROOM_START';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const DELETE_ROOM_FAIL = 'DELETE_ROOM_FAIL';

export const SET_ROOM_SORT_ORDER_START = 'SET_ROOM_SORT_ORDER_START';
export const SET_ROOM_SORT_ORDER_SUCCESS = 'SET_ROOM_SORT_ORDER_SUCCESS';
export const SET_ROOM_SORT_ORDER_FAIL = 'SET_ROOM_SORT_ORDER_FAIL';

export const RESET_CREATED_ROOM = 'RESET_CREATED_ROOM';

export const RESET_ROOM_STORE = 'RESET_ROOM_STORE';
export const RESET_PUBLIC_ROOM_STORE = 'RESET_PUBLIC_ROOM_STORE';

export const LOGOUT = 'LOGOUT';

export type RoomActionTypes =
  | typeof FETCH_ROOMS_START
  | typeof FETCH_ROOMS_SUCCESS
  | typeof FETCH_ROOMS_FAIL
  | typeof FETCH_ROOM_START
  | typeof FETCH_ROOM_SUCCESS
  | typeof FETCH_ROOM_FAIL
  | typeof FETCH_PUBLIC_ROOM_START
  | typeof FETCH_PUBLIC_ROOM_SUCCESS
  | typeof FETCH_PUBLIC_ROOM_FAIL
  | typeof CREATE_ROOM_START
  | typeof CREATE_ROOM_SUCCESS
  | typeof CREATE_ROOM_FAIL
  | typeof UPDATE_ROOM_START
  | typeof UPDATE_ROOM_SUCCESS
  | typeof UPDATE_ROOM_FAIL
  | typeof DELETE_ROOM_START
  | typeof DELETE_ROOM_SUCCESS
  | typeof DELETE_ROOM_FAIL
  | typeof SET_ROOM_SORT_ORDER_START
  | typeof SET_ROOM_SORT_ORDER_SUCCESS
  | typeof SET_ROOM_SORT_ORDER_FAIL
  | typeof RESET_CREATED_ROOM
  | typeof RESET_ROOM_STORE
  | typeof RESET_PUBLIC_ROOM_STORE
  | typeof LOGOUT;
