import * as actionTypes from './actionTypes';
import { NightsPeriodActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { NightsPeriod } from '../../domain/NightsPeriod';

export type NightsPeriodStateType = {
  nightsPeriodCreateLoading: boolean;
  nightsPeriodCreateError: HttpError;
  nightsPeriodCreateSuccess: boolean;
  createdNightsPeriod: NightsPeriod | null;
  nightsPeriodUpdateLoading: boolean;
  nightsPeriodUpdateError: HttpError;
  nightsPeriodUpdateSuccess: boolean;
  updatedNightsPeriod: NightsPeriod | null;
  nightsPeriodDeleteLoading: boolean;
  nightsPeriodDeleteError: HttpError;
  nightsPeriodDeleteSuccess: boolean;
};

export type NightsPeriodActionType = NightsPeriodStateType & {
  type: NightsPeriodActionTypes;
};

export const initialState: NightsPeriodStateType = {
  nightsPeriodCreateLoading: false,
  nightsPeriodCreateError: null,
  nightsPeriodCreateSuccess: false,
  createdNightsPeriod: null,
  nightsPeriodUpdateLoading: false,
  nightsPeriodUpdateError: null,
  nightsPeriodUpdateSuccess: false,
  updatedNightsPeriod: null,
  nightsPeriodDeleteLoading: false,
  nightsPeriodDeleteError: null,
  nightsPeriodDeleteSuccess: false,
};

const createNightsPeriodStart = (
  state: NightsPeriodStateType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodCreateLoading: true,
});

const createNightsPeriodSuccess = (
  state: NightsPeriodStateType,
  action: NightsPeriodActionType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodCreateLoading: false,
  nightsPeriodCreateError: null,
  nightsPeriodCreateSuccess: true,
  createdNightsPeriod: action.createdNightsPeriod,
});

const createNightsPeriodFail = (
  state: NightsPeriodStateType,
  action: NightsPeriodActionType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodCreateLoading: false,
  nightsPeriodCreateError: action.nightsPeriodCreateError,
});

const updateNightsPeriodStart = (
  state: NightsPeriodStateType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodUpdateLoading: true,
});

const updateNightsPeriodSuccess = (
  state: NightsPeriodStateType,
  action: NightsPeriodActionType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodUpdateLoading: false,
  nightsPeriodUpdateError: null,
  nightsPeriodUpdateSuccess: true,
  updatedNightsPeriod: action.updatedNightsPeriod,
});

const updateNightsPeriodFail = (
  state: NightsPeriodStateType,
  action: NightsPeriodActionType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodUpdateLoading: false,
  nightsPeriodUpdateError: action.nightsPeriodUpdateError,
});

const deleteNightsPeriodStart = (
  state: NightsPeriodStateType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodDeleteLoading: true,
  nightsPeriodDeleteSuccess: false,
});

const deleteNightsPeriodSuccess = (
  state: NightsPeriodStateType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodDeleteLoading: false,
  nightsPeriodDeleteError: null,
  nightsPeriodDeleteSuccess: true,
});

const deleteNightsPeriodFail = (
  state: NightsPeriodStateType,
  action: NightsPeriodActionType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodDeleteLoading: false,
  nightsPeriodDeleteError: action.nightsPeriodDeleteError,
});

const resetCreatedNightsPeriod = (
  state: NightsPeriodStateType,
): NightsPeriodStateType => ({
  ...state,
  createdNightsPeriod: null,
});

const resetUpdatedNightsPeriod = (
  state: NightsPeriodStateType,
): NightsPeriodStateType => ({
  ...state,
  updatedNightsPeriod: null,
});

const resetDeletedNightsPeriod = (
  state: NightsPeriodStateType,
): NightsPeriodStateType => ({
  ...state,
  nightsPeriodDeleteLoading: false,
  nightsPeriodDeleteError: null,
  nightsPeriodDeleteSuccess: false,
});

const resetNightsPeriodStore = (): NightsPeriodStateType => ({
  ...initialState,
});

const completelyResetNightsPeriodStore = (): NightsPeriodStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: NightsPeriodActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_NIGHTS_PERIOD_START:
      return createNightsPeriodStart(state);
    case actionTypes.CREATE_NIGHTS_PERIOD_SUCCESS:
      return createNightsPeriodSuccess(state, action);
    case actionTypes.CREATE_NIGHTS_PERIOD_FAIL:
      return createNightsPeriodFail(state, action);
    case actionTypes.UPDATE_NIGHTS_PERIOD_START:
      return updateNightsPeriodStart(state);
    case actionTypes.UPDATE_NIGHTS_PERIOD_SUCCESS:
      return updateNightsPeriodSuccess(state, action);
    case actionTypes.UPDATE_NIGHTS_PERIOD_FAIL:
      return updateNightsPeriodFail(state, action);
    case actionTypes.DELETE_NIGHTS_PERIOD_START:
      return deleteNightsPeriodStart(state);
    case actionTypes.DELETE_NIGHTS_PERIOD_SUCCESS:
      return deleteNightsPeriodSuccess(state);
    case actionTypes.DELETE_NIGHTS_PERIOD_FAIL:
      return deleteNightsPeriodFail(state, action);
    case actionTypes.RESET_CREATED_NIGHTS_PERIOD:
      return resetCreatedNightsPeriod(state);
    case actionTypes.RESET_UPDATED_NIGHTS_PERIOD:
      return resetUpdatedNightsPeriod(state);
    case actionTypes.RESET_DELETED_NIGHTS_PERIOD:
      return resetDeletedNightsPeriod(state);
    case actionTypes.RESET_NIGHTS_PERIOD_STORE:
      return resetNightsPeriodStore();
    case actionTypes.LOGOUT:
      return completelyResetNightsPeriodStore();
    default:
      return state;
  }
};

export default reducer;
