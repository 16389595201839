import * as actionTypes from './actionTypes';
import { LocationDiscountActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { LocationDiscount } from '../../domain/LocationDiscount';
import { ListResults } from '../../common/List/List';

export type LocationDiscountStateType = {
  locationDiscountsList: ListResults<LocationDiscount> | null;
  locationDiscountsLoading: boolean;
  locationDiscountsError: HttpError;
  locationDiscountsListUpdateNeeded: boolean;
  locationDiscountCreateLoading: boolean;
  locationDiscountCreateError: HttpError;
  locationDiscountCreateSuccess: boolean;
  createdLocationDiscount: LocationDiscount | null;
  locationDiscountUpdateLoading: boolean;
  locationDiscountUpdateError: HttpError;
  locationDiscountUpdateSuccess: boolean;
  locationDiscountDeleteLoading: boolean;
  locationDiscountDeleteError: HttpError;
  locationDiscountDeleteSuccess: boolean;
};

export type LocationDiscountActionType = LocationDiscountStateType & {
  type: LocationDiscountActionTypes;
};

export const initialState: LocationDiscountStateType = {
  locationDiscountsList: null,
  locationDiscountsLoading: true,
  locationDiscountsError: null,
  locationDiscountsListUpdateNeeded: false,
  locationDiscountCreateLoading: false,
  locationDiscountCreateError: null,
  locationDiscountCreateSuccess: false,
  createdLocationDiscount: null,
  locationDiscountUpdateLoading: false,
  locationDiscountUpdateError: null,
  locationDiscountUpdateSuccess: false,
  locationDiscountDeleteLoading: false,
  locationDiscountDeleteError: null,
  locationDiscountDeleteSuccess: false,
};

const fetchLocationDiscountsStart = (
  state: LocationDiscountStateType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountsLoading: true,
});

const fetchLocationDiscountsSuccess = (
  state: LocationDiscountStateType,
  action: LocationDiscountActionType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountsList: action.locationDiscountsList,
  locationDiscountsLoading: false,
  locationDiscountsError: null,
  locationDiscountsListUpdateNeeded: false,
});

const fetchLocationDiscountsFail = (
  state: LocationDiscountStateType,
  action: LocationDiscountActionType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountsError: action.locationDiscountsError,
  locationDiscountsLoading: false,
});

const createLocationDiscountStart = (
  state: LocationDiscountStateType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountCreateLoading: true,
});

const createLocationDiscountSuccess = (
  state: LocationDiscountStateType,
  action: LocationDiscountActionType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountCreateLoading: false,
  locationDiscountCreateError: null,
  locationDiscountCreateSuccess: true,
  createdLocationDiscount: action.createdLocationDiscount,
  locationDiscountsListUpdateNeeded: true,
});

const createLocationDiscountFail = (
  state: LocationDiscountStateType,
  action: LocationDiscountActionType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountCreateLoading: false,
  locationDiscountCreateError: action.locationDiscountCreateError,
});

const updateLocationDiscountStart = (
  state: LocationDiscountStateType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountUpdateLoading: true,
});

const updateLocationDiscountSuccess = (
  state: LocationDiscountStateType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountUpdateLoading: false,
  locationDiscountUpdateError: null,
  locationDiscountUpdateSuccess: true,
  locationDiscountsListUpdateNeeded: true,
});

const updateLocationDiscountFail = (
  state: LocationDiscountStateType,
  action: LocationDiscountActionType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountUpdateLoading: false,
  locationDiscountUpdateError: action.locationDiscountUpdateError,
});

const deleteLocationDiscountStart = (
  state: LocationDiscountStateType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountDeleteLoading: true,
});

const deleteLocationDiscountSuccess = (
  state: LocationDiscountStateType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountDeleteLoading: false,
  locationDiscountDeleteError: null,
  locationDiscountDeleteSuccess: true,
  locationDiscountsListUpdateNeeded: true,
});

const deleteLocationDiscountFail = (
  state: LocationDiscountStateType,
  action: LocationDiscountActionType,
): LocationDiscountStateType => ({
  ...state,
  locationDiscountDeleteLoading: false,
  locationDiscountDeleteError: action.locationDiscountDeleteError,
});

const resetLocationDiscountStore = (
  state: LocationDiscountStateType,
): LocationDiscountStateType => ({
  ...initialState,
});

const completelyResetLocationDiscountStore = (): LocationDiscountStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LocationDiscountActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATION_DISCOUNTS_START:
      return fetchLocationDiscountsStart(state);
    case actionTypes.FETCH_LOCATION_DISCOUNTS_SUCCESS:
      return fetchLocationDiscountsSuccess(state, action);
    case actionTypes.FETCH_LOCATION_DISCOUNTS_FAIL:
      return fetchLocationDiscountsFail(state, action);
    case actionTypes.CREATE_LOCATION_DISCOUNT_START:
      return createLocationDiscountStart(state);
    case actionTypes.CREATE_LOCATION_DISCOUNT_SUCCESS:
      return createLocationDiscountSuccess(state, action);
    case actionTypes.CREATE_LOCATION_DISCOUNT_FAIL:
      return createLocationDiscountFail(state, action);
    case actionTypes.UPDATE_LOCATION_DISCOUNT_START:
      return updateLocationDiscountStart(state);
    case actionTypes.UPDATE_LOCATION_DISCOUNT_SUCCESS:
      return updateLocationDiscountSuccess(state);
    case actionTypes.UPDATE_LOCATION_DISCOUNT_FAIL:
      return updateLocationDiscountFail(state, action);
    case actionTypes.DELETE_LOCATION_DISCOUNT_START:
      return deleteLocationDiscountStart(state);
    case actionTypes.DELETE_LOCATION_DISCOUNT_SUCCESS:
      return deleteLocationDiscountSuccess(state);
    case actionTypes.DELETE_LOCATION_DISCOUNT_FAIL:
      return deleteLocationDiscountFail(state, action);
    case actionTypes.RESET_LOCATION_DISCOUNT_STORE:
      return resetLocationDiscountStore(state);
    case actionTypes.LOGOUT:
      return completelyResetLocationDiscountStore();
    default:
      return state;
  }
};

export default reducer;
