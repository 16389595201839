import * as actionTypes from './actionTypes';
import { ProgramPriceActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ProgramPrice } from '../../domain/ProgramPrice';

export type ProgramPriceStateType = {
  setProgramPriceLoading: boolean;
  setProgramPriceError: HttpError;
  setProgramPriceSuccess: boolean;
  setProgramPrice: ProgramPrice | null;
};

export type ProgramPriceActionType = ProgramPriceStateType & {
  type: ProgramPriceActionTypes;
};

export const initialState: ProgramPriceStateType = {
  setProgramPriceLoading: false,
  setProgramPriceError: null,
  setProgramPriceSuccess: false,
  setProgramPrice: null,
};

const setProgramPriceStart = (
  state: ProgramPriceStateType,
): ProgramPriceStateType => ({
  ...state,
  setProgramPriceLoading: true,
});

const setProgramPriceSuccess = (
  state: ProgramPriceStateType,
  action: ProgramPriceActionType,
): ProgramPriceStateType => ({
  ...state,
  setProgramPriceLoading: false,
  setProgramPriceError: null,
  setProgramPriceSuccess: true,
  setProgramPrice: action.setProgramPrice,
});

const setProgramPriceFail = (
  state: ProgramPriceStateType,
  action: ProgramPriceActionType,
): ProgramPriceStateType => ({
  ...state,
  setProgramPriceLoading: false,
  setProgramPriceError: action.setProgramPriceError,
  setProgramPriceSuccess: false,
  setProgramPrice: null,
});

const resetSetProgramPrice = (
  state: ProgramPriceStateType,
): ProgramPriceStateType => ({
  ...state,
  setProgramPrice: null,
});

const resetProgramPriceStore = (
  state: ProgramPriceStateType,
): ProgramPriceStateType => ({
  ...initialState,
});

const completelyResetProgramPriceStore = (): ProgramPriceStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProgramPriceActionType) => {
  switch (action.type) {
    case actionTypes.SET_PROGRAM_PRICE_START:
      return setProgramPriceStart(state);
    case actionTypes.SET_PROGRAM_PRICE_SUCCESS:
      return setProgramPriceSuccess(state, action);
    case actionTypes.SET_PROGRAM_PRICE_FAIL:
      return setProgramPriceFail(state, action);
    case actionTypes.RESET_SET_PROGRAM_PRICE:
      return resetSetProgramPrice(state);
    case actionTypes.RESET_PROGRAM_PRICE_STORE:
      return resetProgramPriceStore(state);
    case actionTypes.LOGOUT:
      return completelyResetProgramPriceStore();
    default:
      return state;
  }
};

export default reducer;
