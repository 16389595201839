import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ProgramPrice } from '../../domain/ProgramPrice';

export const setProgramPriceStart = () => ({
  type: actionTypes.SET_PROGRAM_PRICE_START,
});

export const setProgramPriceSuccess = (setProgramPrice: ProgramPrice) => ({
  type: actionTypes.SET_PROGRAM_PRICE_SUCCESS,
  setProgramPrice,
});

export const setProgramPriceFail = (setProgramPriceError: HttpError) => ({
  type: actionTypes.SET_PROGRAM_PRICE_FAIL,
  setProgramPriceError,
});

export const resetSetProgramPrice = () => ({
  type: actionTypes.RESET_SET_PROGRAM_PRICE,
});

export const resetProgramPriceStore = () => ({
  type: actionTypes.RESET_PROGRAM_PRICE_STORE,
});
