export const SET_PROGRAM_DISCOUNT_START = 'SET_PROGRAM_DISCOUNT_START';
export const SET_PROGRAM_DISCOUNT_SUCCESS = 'SET_PROGRAM_DISCOUNT_SUCCESS';
export const SET_PROGRAM_DISCOUNT_FAIL = 'SET_PROGRAM_DISCOUNT_FAIL';

export const RESET_SET_PROGRAM_DISCOUNT = 'RESET_SET_PROGRAM_DISCOUNT';

export const RESET_PROGRAM_DISCOUNT_STORE = 'RESET_PROGRAM_DISCOUNT_STORE';

export const LOGOUT = 'LOGOUT';

export type ProgramDiscountActionTypes =
  | typeof SET_PROGRAM_DISCOUNT_START
  | typeof SET_PROGRAM_DISCOUNT_SUCCESS
  | typeof SET_PROGRAM_DISCOUNT_FAIL
  | typeof RESET_SET_PROGRAM_DISCOUNT
  | typeof RESET_PROGRAM_DISCOUNT_STORE
  | typeof LOGOUT;
