export const FETCH_ENQUIRIES_START = 'FETCH_ENQUIRIES_START';
export const FETCH_ENQUIRIES_SUCCESS = 'FETCH_ENQUIRIES_SUCCESS';
export const FETCH_ENQUIRIES_FAIL = 'FETCH_ENQUIRIES_FAIL';

export const FETCH_ENQUIRY_START = 'FETCH_ENQUIRY_START';
export const FETCH_ENQUIRY_SUCCESS = 'FETCH_ENQUIRY_SUCCESS';
export const FETCH_ENQUIRY_FAIL = 'FETCH_ENQUIRY_FAIL';

export const CREATE_ENQUIRY_START = 'CREATE_ENQUIRY_START';
export const CREATE_ENQUIRY_SUCCESS = 'CREATE_ENQUIRY_SUCCESS';
export const CREATE_ENQUIRY_FAIL = 'CREATE_ENQUIRY_FAIL';

export const UPDATE_ENQUIRY_START = 'UPDATE_ENQUIRY_START';
export const UPDATE_ENQUIRY_SUCCESS = 'UPDATE_ENQUIRY_SUCCESS';
export const UPDATE_ENQUIRY_FAIL = 'UPDATE_ENQUIRY_FAIL';

export const DELETE_ENQUIRY_START = 'DELETE_ENQUIRY_START';
export const DELETE_ENQUIRY_SUCCESS = 'DELETE_ENQUIRY_SUCCESS';
export const DELETE_ENQUIRY_FAIL = 'DELETE_ENQUIRY_FAIL';

export const RESET_CREATED_ENQUIRY = 'RESET_CREATED_ENQUIRY';

export const RESET_ENQUIRY_STORE = 'RESET_ENQUIRY_STORE';

export const LOGOUT = 'LOGOUT';

export type EnquiryActionTypes =
  | typeof FETCH_ENQUIRIES_START
  | typeof FETCH_ENQUIRIES_SUCCESS
  | typeof FETCH_ENQUIRIES_FAIL
  | typeof FETCH_ENQUIRY_START
  | typeof FETCH_ENQUIRY_SUCCESS
  | typeof FETCH_ENQUIRY_FAIL
  | typeof CREATE_ENQUIRY_START
  | typeof CREATE_ENQUIRY_SUCCESS
  | typeof CREATE_ENQUIRY_FAIL
  | typeof UPDATE_ENQUIRY_START
  | typeof UPDATE_ENQUIRY_SUCCESS
  | typeof UPDATE_ENQUIRY_FAIL
  | typeof DELETE_ENQUIRY_START
  | typeof DELETE_ENQUIRY_SUCCESS
  | typeof DELETE_ENQUIRY_FAIL
  | typeof RESET_CREATED_ENQUIRY
  | typeof RESET_ENQUIRY_STORE
  | typeof LOGOUT;
