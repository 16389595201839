export const FETCH_CITY_CONTENTS_START = 'FETCH_CITY_CONTENTS_START';
export const FETCH_CITY_CONTENTS_SUCCESS = 'FETCH_CITY_CONTENTS_SUCCESS';
export const FETCH_CITY_CONTENTS_FAIL = 'FETCH_CITY_CONTENTS_FAIL';

export const CREATE_CITY_CONTENT_START = 'CREATE_CITY_CONTENT_START';
export const CREATE_CITY_CONTENT_SUCCESS = 'CREATE_CITY_CONTENT_SUCCESS';
export const CREATE_CITY_CONTENT_FAIL = 'CREATE_CITY_CONTENT_FAIL';

export const UPDATE_CITY_CONTENT_START = 'UPDATE_CITY_CONTENT_START';
export const UPDATE_CITY_CONTENT_SUCCESS = 'UPDATE_CITY_CONTENT_SUCCESS';
export const UPDATE_CITY_CONTENT_FAIL = 'UPDATE_CITY_CONTENT_FAIL';

export const SET_CITY_CONTENT_SORT_ORDER_START =
  'SET_CITY_CONTENT_SORT_ORDER_START';
export const SET_CITY_CONTENT_SORT_ORDER_SUCCESS =
  'SET_CITY_CONTENT_SORT_ORDER_SUCCESS';
export const SET_CITY_CONTENT_SORT_ORDER_FAIL =
  'SET_CITY_CONTENT_SORT_ORDER_FAIL';

export const DELETE_CITY_CONTENT_START = 'DELETE_CITY_CONTENT_START';
export const DELETE_CITY_CONTENT_SUCCESS = 'DELETE_CITY_CONTENT_SUCCESS';
export const DELETE_CITY_CONTENT_FAIL = 'DELETE_CITY_CONTENT_FAIL';

export const RESET_CITY_CONTENT_STORE = 'RESET_CITY_CONTENT_STORE';

export const LOGOUT = 'LOGOUT';

export type CityContentActionTypes =
  | typeof FETCH_CITY_CONTENTS_START
  | typeof FETCH_CITY_CONTENTS_SUCCESS
  | typeof FETCH_CITY_CONTENTS_FAIL
  | typeof CREATE_CITY_CONTENT_START
  | typeof CREATE_CITY_CONTENT_SUCCESS
  | typeof CREATE_CITY_CONTENT_FAIL
  | typeof UPDATE_CITY_CONTENT_START
  | typeof UPDATE_CITY_CONTENT_SUCCESS
  | typeof UPDATE_CITY_CONTENT_FAIL
  | typeof DELETE_CITY_CONTENT_START
  | typeof DELETE_CITY_CONTENT_SUCCESS
  | typeof DELETE_CITY_CONTENT_FAIL
  | typeof SET_CITY_CONTENT_SORT_ORDER_START
  | typeof SET_CITY_CONTENT_SORT_ORDER_SUCCESS
  | typeof SET_CITY_CONTENT_SORT_ORDER_FAIL
  | typeof RESET_CITY_CONTENT_STORE
  | typeof LOGOUT;
