import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Room } from '../../domain/Room';
import { ListResults } from '../../common/List/List';

export const fetchRoomsStart = () => ({
  type: actionTypes.FETCH_ROOMS_START,
});

export const fetchRoomsSuccess = (roomsList: ListResults<Room>) => ({
  type: actionTypes.FETCH_ROOMS_SUCCESS,
  roomsList,
});

export const fetchRoomsFail = (roomsError: HttpError) => ({
  type: actionTypes.FETCH_ROOMS_FAIL,
  roomsError,
});

export const fetchRoomStart = () => ({
  type: actionTypes.FETCH_ROOM_START,
});

export const fetchRoomSuccess = (room: Room) => ({
  type: actionTypes.FETCH_ROOM_SUCCESS,
  room,
});

export const fetchRoomFail = (room: HttpError) => ({
  type: actionTypes.FETCH_ROOM_FAIL,
  room,
});

export const fetchPublicRoomStart = () => ({
  type: actionTypes.FETCH_PUBLIC_ROOM_START,
});

export const fetchPublicRoomSuccess = (publicRoom: Room) => ({
  type: actionTypes.FETCH_PUBLIC_ROOM_SUCCESS,
  publicRoom,
});

export const fetchPublicRoomFail = (publicRoomError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_ROOM_FAIL,
  publicRoomError,
});

export const createRoomStart = () => ({
  type: actionTypes.CREATE_ROOM_START,
});

export const createRoomSuccess = (createdRoom: Room) => ({
  type: actionTypes.CREATE_ROOM_SUCCESS,
  createdRoom,
});

export const createRoomFail = (roomCreateError: HttpError) => ({
  type: actionTypes.CREATE_ROOM_FAIL,
  roomCreateError,
});

export const updateRoomStart = () => ({
  type: actionTypes.UPDATE_ROOM_START,
});

export const updateRoomSuccess = () => ({
  type: actionTypes.UPDATE_ROOM_SUCCESS,
});

export const updateRoomFail = (roomUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_ROOM_FAIL,
  roomUpdateError,
});

export const deleteRoomStart = () => ({
  type: actionTypes.DELETE_ROOM_START,
});

export const deleteRoomSuccess = () => ({
  type: actionTypes.DELETE_ROOM_SUCCESS,
});

export const deleteRoomFail = (roomDeleteError: HttpError) => ({
  type: actionTypes.DELETE_ROOM_FAIL,
  roomDeleteError,
});

export const setRoomSortOrdersStart = () => ({
  type: actionTypes.SET_ROOM_SORT_ORDER_START,
});

export const setRoomSortOrdersSuccess = () => ({
  type: actionTypes.SET_ROOM_SORT_ORDER_SUCCESS,
});

export const setRoomSortOrdersFail = (roomSortOrdersError: HttpError) => ({
  type: actionTypes.SET_ROOM_SORT_ORDER_FAIL,
  roomSortOrdersError,
});

export const resetCreatedRoom = () => ({
  type: actionTypes.RESET_CREATED_ROOM,
});

export const resetRoomStore = () => ({
  type: actionTypes.RESET_ROOM_STORE,
});

export const resetPublicRoomStore = () => ({
  type: actionTypes.RESET_PUBLIC_ROOM_STORE,
});
