import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './actionTypes';
import moment from 'moment';
import { HttpError } from '../../config/Axios/axios-instance';
import { Locale } from '../../domain/Translation';
import { DEFAULT_LOCALE } from '../../config/constants';
import { EnquiryRequest } from '../../domain/Enquiry';

export type AuthStateType = {
  jwtToken: string | null;
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  loginGoogleLoading: boolean;
  loginGoogleError: HttpError;
  lastStoreActionAt: moment.Moment | null;
  refreshTokenLoading: boolean;
  refreshTokenError: HttpError;
  selectedLocale: Locale;
  enquiryRequest: EnquiryRequest | null;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes;
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  lastStoreActionAt: null,
  refreshTokenLoading: false,
  refreshTokenError: null,
  jwtToken: localStorage.getItem('token'),
  selectedLocale:
    (localStorage.getItem('selectedLocale') as Locale) ?? DEFAULT_LOCALE,
  enquiryRequest: localStorage.getItem('enquiryRequest')
    ? JSON.parse(localStorage.getItem('enquiryRequest') ?? '')
    : null,
};

const loginGoogleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: true,
});

const loginGoogleSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginGoogleError: null,
  loginGoogleLoading: false,
  jwtToken: action.jwtToken,
});

const loginGoogleFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  loginGoogleError: action.loginGoogleError,
  loginGoogleLoading: false,
});

const refreshTokenStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  refreshTokenLoading: true,
});

const refreshTokenSuccess = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refreshTokenLoading: false,
  refreshTokenError: null,
  isInitCompleted: true,
  isAuthenticated: true,
  jwtToken: action.jwtToken,
});

const refreshTokenFail = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => ({
  ...state,
  refreshTokenError: action.refreshTokenError,
  refreshTokenLoading: false,
});

const createEnquiryRequest = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => {
  localStorage.setItem('enquiryRequest', JSON.stringify(action.enquiryRequest));

  return {
    ...state,
    enquiryRequest: action.enquiryRequest,
  };
};

const cancelEnquiryRequest = (state: AuthStateType): AuthStateType => {
  localStorage.removeItem('enquiryRequest');

  return {
    ...state,
    enquiryRequest: null,
  };
};

const logout = (state: AuthStateType): AuthStateType => ({
  ...state,
  jwtToken: null,
  isAuthenticated: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  lastStoreActionAt: null,
  refreshTokenError: null,
  refreshTokenLoading: false,
  isInitCompleted: true,
});

const selectLocale = (
  state: AuthStateType,
  action: AuthActionType,
): AuthStateType => {
  localStorage.setItem('selectedLocale', action.selectedLocale);

  return {
    ...state,
    selectedLocale: action.selectedLocale,
  };
};

const updateLastAction = (state: AuthStateType): AuthStateType => ({
  ...state,
  lastStoreActionAt: moment(),
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_GOOGLE_START:
      return loginGoogleStart(state);
    case actionTypes.LOGIN_GOOGLE_SUCCESS:
      return loginGoogleSuccess(state, action);
    case actionTypes.LOGIN_GOOGLE_FAIL:
      return loginGoogleFail(state, action);
    case actionTypes.REFRESH_TOKEN_START:
      return refreshTokenStart(state);
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return refreshTokenSuccess(state, action);
    case actionTypes.REFRESH_TOKEN_FAIL:
      return refreshTokenFail(state, action);
    case actionTypes.CREATE_ENQUIRY_REQUEST:
      return createEnquiryRequest(state, action);
    case actionTypes.CANCEL_ENQUIRY_REQUEST:
      return cancelEnquiryRequest(state);
    case actionTypes.SELECT_LOCALE:
      return selectLocale(state, action);
    case actionTypes.LOGOUT:
      return logout(state);
    default:
      return updateLastAction(state);
  }
};

export default reducer;
