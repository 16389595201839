import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Age } from '../../domain/Age';
import { ListResults } from '../../common/List/List';

export const fetchAgesStart = () => ({
  type: actionTypes.FETCH_AGES_START,
});

export const fetchAgesSuccess = (agesList: ListResults<Age>) => ({
  type: actionTypes.FETCH_AGES_SUCCESS,
  agesList,
});

export const fetchAgesFail = (agesError: HttpError) => ({
  type: actionTypes.FETCH_AGES_FAIL,
  agesError,
});

export const fetchAgeStart = () => ({
  type: actionTypes.FETCH_AGE_START,
});

export const fetchAgeSuccess = (age: Age) => ({
  type: actionTypes.FETCH_AGE_SUCCESS,
  age,
});

export const fetchAgeFail = (age: HttpError) => ({
  type: actionTypes.FETCH_AGE_FAIL,
  age,
});

export const createAgeStart = () => ({
  type: actionTypes.CREATE_AGE_START,
});

export const createAgeSuccess = (createdAge: Age) => ({
  type: actionTypes.CREATE_AGE_SUCCESS,
  createdAge,
});

export const createAgeFail = (ageCreateError: HttpError) => ({
  type: actionTypes.CREATE_AGE_FAIL,
  ageCreateError,
});

export const updateAgeStart = () => ({
  type: actionTypes.UPDATE_AGE_START,
});

export const updateAgeSuccess = () => ({
  type: actionTypes.UPDATE_AGE_SUCCESS,
});

export const updateAgeFail = (ageUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_AGE_FAIL,
  ageUpdateError,
});

export const deleteAgeStart = () => ({
  type: actionTypes.DELETE_AGE_START,
});

export const deleteAgeSuccess = () => ({
  type: actionTypes.DELETE_AGE_SUCCESS,
});

export const deleteAgeFail = (ageDeleteError: HttpError) => ({
  type: actionTypes.DELETE_AGE_FAIL,
  ageDeleteError,
});

export const resetCreatedAge = () => ({
  type: actionTypes.RESET_CREATED_AGE,
});

export const resetAgeStore = () => ({
  type: actionTypes.RESET_AGE_STORE,
});
