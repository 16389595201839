import * as actionTypes from './actionTypes';
import { CityContentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { CityContent } from '../../domain/CityContent';
import { ListResults } from '../../common/List/List';

export type CityContentStateType = {
  cityContentsList: ListResults<CityContent> | null;
  cityContentsLoading: boolean;
  cityContentsError: HttpError;
  cityContentsListUpdateNeeded: boolean;
  cityContentCreateLoading: boolean;
  cityContentCreateError: HttpError;
  cityContentCreateSuccess: boolean;
  createdCityContent: CityContent | null;
  cityContentUpdateLoading: boolean;
  cityContentUpdateError: HttpError;
  cityContentUpdateSuccess: boolean;
  cityContentDeleteLoading: boolean;
  cityContentDeleteError: HttpError;
  cityContentDeleteSuccess: boolean;
  cityContentSortOrdersLoading: boolean;
  cityContentSortOrdersError: HttpError;
  cityContentSortOrdersSuccess: boolean;
};

export type CityContentActionType = CityContentStateType & {
  type: CityContentActionTypes;
};

export const initialState: CityContentStateType = {
  cityContentsList: null,
  cityContentsLoading: true,
  cityContentsError: null,
  cityContentsListUpdateNeeded: false,
  cityContentCreateLoading: false,
  cityContentCreateError: null,
  cityContentCreateSuccess: false,
  createdCityContent: null,
  cityContentUpdateLoading: false,
  cityContentUpdateError: null,
  cityContentUpdateSuccess: false,
  cityContentDeleteLoading: false,
  cityContentDeleteError: null,
  cityContentDeleteSuccess: false,
  cityContentSortOrdersLoading: false,
  cityContentSortOrdersError: null,
  cityContentSortOrdersSuccess: false,
};

const fetchCityContentsStart = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...state,
  cityContentsLoading: true,
});

const fetchCityContentsSuccess = (
  state: CityContentStateType,
  action: CityContentActionType,
): CityContentStateType => ({
  ...state,
  cityContentsList: action.cityContentsList,
  cityContentsLoading: false,
  cityContentsError: null,
  cityContentsListUpdateNeeded: false,
});

const fetchCityContentsFail = (
  state: CityContentStateType,
  action: CityContentActionType,
): CityContentStateType => ({
  ...state,
  cityContentsError: action.cityContentsError,
  cityContentsLoading: false,
});

const createCityContentStart = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...state,
  cityContentCreateLoading: true,
});

const createCityContentSuccess = (
  state: CityContentStateType,
  action: CityContentActionType,
): CityContentStateType => ({
  ...state,
  cityContentCreateLoading: false,
  cityContentCreateError: null,
  cityContentCreateSuccess: true,
  createdCityContent: action.createdCityContent,
  cityContentsListUpdateNeeded: true,
});

const createCityContentFail = (
  state: CityContentStateType,
  action: CityContentActionType,
): CityContentStateType => ({
  ...state,
  cityContentCreateLoading: false,
  cityContentCreateError: action.cityContentCreateError,
});

const updateCityContentStart = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...state,
  cityContentUpdateLoading: true,
});

const updateCityContentSuccess = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...state,
  cityContentUpdateLoading: false,
  cityContentUpdateError: null,
  cityContentUpdateSuccess: true,
  cityContentsListUpdateNeeded: true,
});

const updateCityContentFail = (
  state: CityContentStateType,
  action: CityContentActionType,
): CityContentStateType => ({
  ...state,
  cityContentUpdateLoading: false,
  cityContentUpdateError: action.cityContentUpdateError,
});

const deleteCityContentStart = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...state,
  cityContentDeleteLoading: true,
});

const deleteCityContentSuccess = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...state,
  cityContentDeleteLoading: false,
  cityContentDeleteError: null,
  cityContentDeleteSuccess: true,
  cityContentsListUpdateNeeded: true,
});

const deleteCityContentFail = (
  state: CityContentStateType,
  action: CityContentActionType,
): CityContentStateType => ({
  ...state,
  cityContentDeleteLoading: false,
  cityContentDeleteError: action.cityContentDeleteError,
});

const setCityContentSortOrdersStart = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...state,
  cityContentSortOrdersLoading: true,
  cityContentSortOrdersSuccess: false,
});

const setCityContentSortOrdersSuccess = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...state,
  cityContentSortOrdersLoading: false,
  cityContentSortOrdersError: null,
  cityContentsListUpdateNeeded: true,
  cityContentSortOrdersSuccess: true,
});

const setCityContentSortOrdersFail = (
  state: CityContentStateType,
  action: CityContentActionType,
): CityContentStateType => ({
  ...state,
  cityContentSortOrdersError: action.cityContentSortOrdersError,
  cityContentSortOrdersSuccess: false,
  cityContentSortOrdersLoading: false,
});

const resetCityContentStore = (
  state: CityContentStateType,
): CityContentStateType => ({
  ...initialState,
});

const completelyResetCityContentStore = (): CityContentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CityContentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CITY_CONTENTS_START:
      return fetchCityContentsStart(state);
    case actionTypes.FETCH_CITY_CONTENTS_SUCCESS:
      return fetchCityContentsSuccess(state, action);
    case actionTypes.FETCH_CITY_CONTENTS_FAIL:
      return fetchCityContentsFail(state, action);
    case actionTypes.CREATE_CITY_CONTENT_START:
      return createCityContentStart(state);
    case actionTypes.CREATE_CITY_CONTENT_SUCCESS:
      return createCityContentSuccess(state, action);
    case actionTypes.CREATE_CITY_CONTENT_FAIL:
      return createCityContentFail(state, action);
    case actionTypes.UPDATE_CITY_CONTENT_START:
      return updateCityContentStart(state);
    case actionTypes.UPDATE_CITY_CONTENT_SUCCESS:
      return updateCityContentSuccess(state);
    case actionTypes.UPDATE_CITY_CONTENT_FAIL:
      return updateCityContentFail(state, action);
    case actionTypes.DELETE_CITY_CONTENT_START:
      return deleteCityContentStart(state);
    case actionTypes.DELETE_CITY_CONTENT_SUCCESS:
      return deleteCityContentSuccess(state);
    case actionTypes.DELETE_CITY_CONTENT_FAIL:
      return deleteCityContentFail(state, action);
    case actionTypes.SET_CITY_CONTENT_SORT_ORDER_START:
      return setCityContentSortOrdersStart(state);
    case actionTypes.SET_CITY_CONTENT_SORT_ORDER_SUCCESS:
      return setCityContentSortOrdersSuccess(state);
    case actionTypes.SET_CITY_CONTENT_SORT_ORDER_FAIL:
      return setCityContentSortOrdersFail(state, action);
    case actionTypes.RESET_CITY_CONTENT_STORE:
      return resetCityContentStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCityContentStore();
    default:
      return state;
  }
};

export default reducer;
