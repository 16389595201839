import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ProgramDiscount } from '../../domain/ProgramDiscount';

export const setProgramDiscountStart = () => ({
  type: actionTypes.SET_PROGRAM_DISCOUNT_START,
});

export const setProgramDiscountSuccess = (
  setProgramDiscount: ProgramDiscount,
) => ({
  type: actionTypes.SET_PROGRAM_DISCOUNT_SUCCESS,
  setProgramDiscount,
});

export const setProgramDiscountFail = (setProgramDiscountError: HttpError) => ({
  type: actionTypes.SET_PROGRAM_DISCOUNT_FAIL,
  setProgramDiscountError,
});

export const resetSetProgramDiscount = () => ({
  type: actionTypes.RESET_SET_PROGRAM_DISCOUNT,
});

export const resetProgramDiscountStore = () => ({
  type: actionTypes.RESET_PROGRAM_DISCOUNT_STORE,
});
