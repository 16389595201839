import * as actionTypes from './actionTypes';
import { LocationContentActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { LocationContent } from '../../domain/LocationContent';
import { ListResults } from '../../common/List/List';

export type LocationContentStateType = {
  locationContentsList: ListResults<LocationContent> | null;
  locationContentsLoading: boolean;
  locationContentsError: HttpError;
  locationContentsListUpdateNeeded: boolean;
  locationContentCreateLoading: boolean;
  locationContentCreateError: HttpError;
  locationContentCreateSuccess: boolean;
  createdLocationContent: LocationContent | null;
  locationContentUpdateLoading: boolean;
  locationContentUpdateError: HttpError;
  locationContentUpdateSuccess: boolean;
  locationContentDeleteLoading: boolean;
  locationContentDeleteError: HttpError;
  locationContentDeleteSuccess: boolean;
  locationContentSortOrdersLoading: boolean;
  locationContentSortOrdersError: HttpError;
  locationContentSortOrdersSuccess: boolean;
};

export type LocationContentActionType = LocationContentStateType & {
  type: LocationContentActionTypes;
};

export const initialState: LocationContentStateType = {
  locationContentsList: null,
  locationContentsLoading: true,
  locationContentsError: null,
  locationContentsListUpdateNeeded: false,
  locationContentCreateLoading: false,
  locationContentCreateError: null,
  locationContentCreateSuccess: false,
  createdLocationContent: null,
  locationContentUpdateLoading: false,
  locationContentUpdateError: null,
  locationContentUpdateSuccess: false,
  locationContentDeleteLoading: false,
  locationContentDeleteError: null,
  locationContentDeleteSuccess: false,
  locationContentSortOrdersLoading: false,
  locationContentSortOrdersError: null,
  locationContentSortOrdersSuccess: false,
};

const fetchLocationContentsStart = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...state,
  locationContentsLoading: true,
});

const fetchLocationContentsSuccess = (
  state: LocationContentStateType,
  action: LocationContentActionType,
): LocationContentStateType => ({
  ...state,
  locationContentsList: action.locationContentsList,
  locationContentsLoading: false,
  locationContentsError: null,
  locationContentsListUpdateNeeded: false,
});

const fetchLocationContentsFail = (
  state: LocationContentStateType,
  action: LocationContentActionType,
): LocationContentStateType => ({
  ...state,
  locationContentsError: action.locationContentsError,
  locationContentsLoading: false,
});

const createLocationContentStart = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...state,
  locationContentCreateLoading: true,
});

const createLocationContentSuccess = (
  state: LocationContentStateType,
  action: LocationContentActionType,
): LocationContentStateType => ({
  ...state,
  locationContentCreateLoading: false,
  locationContentCreateError: null,
  locationContentCreateSuccess: true,
  createdLocationContent: action.createdLocationContent,
  locationContentsListUpdateNeeded: true,
});

const createLocationContentFail = (
  state: LocationContentStateType,
  action: LocationContentActionType,
): LocationContentStateType => ({
  ...state,
  locationContentCreateLoading: false,
  locationContentCreateError: action.locationContentCreateError,
});

const updateLocationContentStart = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...state,
  locationContentUpdateLoading: true,
});

const updateLocationContentSuccess = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...state,
  locationContentUpdateLoading: false,
  locationContentUpdateError: null,
  locationContentUpdateSuccess: true,
  locationContentsListUpdateNeeded: true,
});

const updateLocationContentFail = (
  state: LocationContentStateType,
  action: LocationContentActionType,
): LocationContentStateType => ({
  ...state,
  locationContentUpdateLoading: false,
  locationContentUpdateError: action.locationContentUpdateError,
});

const deleteLocationContentStart = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...state,
  locationContentDeleteLoading: true,
});

const deleteLocationContentSuccess = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...state,
  locationContentDeleteLoading: false,
  locationContentDeleteError: null,
  locationContentDeleteSuccess: true,
  locationContentsListUpdateNeeded: true,
});

const deleteLocationContentFail = (
  state: LocationContentStateType,
  action: LocationContentActionType,
): LocationContentStateType => ({
  ...state,
  locationContentDeleteLoading: false,
  locationContentDeleteError: action.locationContentDeleteError,
});

const setLocationContentSortOrdersStart = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...state,
  locationContentSortOrdersLoading: true,
  locationContentSortOrdersSuccess: false,
});

const setLocationContentSortOrdersSuccess = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...state,
  locationContentSortOrdersLoading: false,
  locationContentSortOrdersError: null,
  locationContentsListUpdateNeeded: true,
  locationContentSortOrdersSuccess: true,
});

const setLocationContentSortOrdersFail = (
  state: LocationContentStateType,
  action: LocationContentActionType,
): LocationContentStateType => ({
  ...state,
  locationContentSortOrdersError: action.locationContentSortOrdersError,
  locationContentSortOrdersSuccess: false,
  locationContentSortOrdersLoading: false,
});

const resetLocationContentStore = (
  state: LocationContentStateType,
): LocationContentStateType => ({
  ...initialState,
});

const completelyResetLocationContentStore = (): LocationContentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LocationContentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATION_CONTENTS_START:
      return fetchLocationContentsStart(state);
    case actionTypes.FETCH_LOCATION_CONTENTS_SUCCESS:
      return fetchLocationContentsSuccess(state, action);
    case actionTypes.FETCH_LOCATION_CONTENTS_FAIL:
      return fetchLocationContentsFail(state, action);
    case actionTypes.CREATE_LOCATION_CONTENT_START:
      return createLocationContentStart(state);
    case actionTypes.CREATE_LOCATION_CONTENT_SUCCESS:
      return createLocationContentSuccess(state, action);
    case actionTypes.CREATE_LOCATION_CONTENT_FAIL:
      return createLocationContentFail(state, action);
    case actionTypes.UPDATE_LOCATION_CONTENT_START:
      return updateLocationContentStart(state);
    case actionTypes.UPDATE_LOCATION_CONTENT_SUCCESS:
      return updateLocationContentSuccess(state);
    case actionTypes.UPDATE_LOCATION_CONTENT_FAIL:
      return updateLocationContentFail(state, action);
    case actionTypes.DELETE_LOCATION_CONTENT_START:
      return deleteLocationContentStart(state);
    case actionTypes.DELETE_LOCATION_CONTENT_SUCCESS:
      return deleteLocationContentSuccess(state);
    case actionTypes.DELETE_LOCATION_CONTENT_FAIL:
      return deleteLocationContentFail(state, action);
    case actionTypes.SET_LOCATION_CONTENT_SORT_ORDER_START:
      return setLocationContentSortOrdersStart(state);
    case actionTypes.SET_LOCATION_CONTENT_SORT_ORDER_SUCCESS:
      return setLocationContentSortOrdersSuccess(state);
    case actionTypes.SET_LOCATION_CONTENT_SORT_ORDER_FAIL:
      return setLocationContentSortOrdersFail(state, action);
    case actionTypes.RESET_LOCATION_CONTENT_STORE:
      return resetLocationContentStore(state);
    case actionTypes.LOGOUT:
      return completelyResetLocationContentStore();
    default:
      return state;
  }
};

export default reducer;
