import * as actionTypes from './actionTypes';
import { CityActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { City, CityOption } from '../../domain/City';
import { PublicTopNavigationItem } from '../../domain/PublicTopNavigationItem';
import { ListResults } from '../../common/List/List';

export type CityStateType = {
  citiesList: ListResults<City> | null;
  citiesLoading: boolean;
  citiesError: HttpError;
  citiesListUpdateNeeded: boolean;
  cityCreateLoading: boolean;
  cityCreateError: HttpError;
  cityCreateSuccess: boolean;
  createdCity: City | null;
  cityUpdateLoading: boolean;
  cityUpdateError: HttpError;
  cityUpdateSuccess: boolean;
  cityDeleteLoading: boolean;
  cityDeleteError: HttpError;
  cityDeleteSuccess: boolean;
  city: City | null;
  cityLoading: boolean;
  cityError: HttpError;
  cityPublicOptions: CityOption[];
  cityPublicOptionsLoading: boolean;
  cityPublicOptionsError: HttpError;
  publicTopNavigationItems: PublicTopNavigationItem[];
  publicTopNavigationItemsLoading: boolean;
  publicTopNavigationItemsError: HttpError;
};

export type CityActionType = CityStateType & {
  type: CityActionTypes;
};

export const initialState: CityStateType = {
  citiesList: null,
  citiesLoading: true,
  citiesError: null,
  citiesListUpdateNeeded: false,
  cityCreateLoading: false,
  cityCreateError: null,
  cityCreateSuccess: false,
  createdCity: null,
  cityUpdateLoading: false,
  cityUpdateError: null,
  cityUpdateSuccess: false,
  cityDeleteLoading: false,
  cityDeleteError: null,
  cityDeleteSuccess: false,
  city: null,
  cityLoading: false,
  cityError: null,
  cityPublicOptions: [],
  cityPublicOptionsLoading: true,
  cityPublicOptionsError: null,
  publicTopNavigationItems: [],
  publicTopNavigationItemsLoading: true,
  publicTopNavigationItemsError: null,
};

const fetchCitiesStart = (state: CityStateType): CityStateType => ({
  ...state,
  citiesLoading: true,
});

const fetchCitiesSuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  citiesList: action.citiesList,
  citiesLoading: false,
  citiesError: null,
  citiesListUpdateNeeded: false,
});

const fetchCitiesFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  citiesError: action.citiesError,
  citiesLoading: false,
});

const fetchCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityLoading: true,
  cityCreateSuccess: false,
  cityCreateError: null,
});

const fetchCitySuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  city: action.city,
  cityLoading: false,
  cityError: null,
});

const fetchCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityError: action.cityError,
  cityLoading: false,
});

const createCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityCreateLoading: true,
});

const createCitySuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityCreateLoading: false,
  cityCreateError: null,
  cityCreateSuccess: true,
  createdCity: action.createdCity,
});

const createCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityCreateLoading: false,
  cityCreateError: action.cityCreateError,
});

const updateCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityUpdateLoading: true,
  cityUpdateSuccess: false,
});

const updateCitySuccess = (state: CityStateType): CityStateType => ({
  ...state,
  cityUpdateLoading: false,
  cityUpdateError: null,
  cityUpdateSuccess: true,
});

const updateCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityUpdateLoading: false,
  cityUpdateError: action.cityUpdateError,
});

const deleteCityStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityDeleteLoading: true,
});

const deleteCitySuccess = (state: CityStateType): CityStateType => ({
  ...state,
  cityDeleteLoading: false,
  cityDeleteError: null,
  cityDeleteSuccess: true,
  citiesListUpdateNeeded: true,
});

const deleteCityFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityDeleteLoading: false,
  cityDeleteError: action.cityDeleteError,
});

const fetchPublicCityOptionsStart = (state: CityStateType): CityStateType => ({
  ...state,
  cityPublicOptionsLoading: true,
});

const fetchPublicCityOptionsSuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityPublicOptions: action.cityPublicOptions,
  cityPublicOptionsLoading: false,
  cityPublicOptionsError: null,
});

const fetchPublicCityOptionsFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  cityPublicOptionsError: action.cityPublicOptionsError,
  cityPublicOptionsLoading: false,
});

const fetchPublicTopNavigationItemsStart = (
  state: CityStateType,
): CityStateType => ({
  ...state,
  publicTopNavigationItemsLoading: true,
});

const fetchPublicTopNavigationItemsSuccess = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  publicTopNavigationItems: action.publicTopNavigationItems,
  publicTopNavigationItemsLoading: false,
  publicTopNavigationItemsError: null,
});

const fetchPublicTopNavigationItemsFail = (
  state: CityStateType,
  action: CityActionType,
): CityStateType => ({
  ...state,
  publicTopNavigationItemsError: action.publicTopNavigationItemsError,
  publicTopNavigationItemsLoading: false,
});

const resetCreatedCity = (state: CityStateType): CityStateType => ({
  ...state,
  createdCity: null,
});

const resetCityStore = (): CityStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CityActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CITIES_START:
      return fetchCitiesStart(state);
    case actionTypes.FETCH_CITIES_SUCCESS:
      return fetchCitiesSuccess(state, action);
    case actionTypes.FETCH_CITIES_FAIL:
      return fetchCitiesFail(state, action);
    case actionTypes.FETCH_CITY_START:
      return fetchCityStart(state);
    case actionTypes.FETCH_CITY_SUCCESS:
      return fetchCitySuccess(state, action);
    case actionTypes.FETCH_CITY_FAIL:
      return fetchCityFail(state, action);
    case actionTypes.CREATE_CITY_START:
      return createCityStart(state);
    case actionTypes.CREATE_CITY_SUCCESS:
      return createCitySuccess(state, action);
    case actionTypes.CREATE_CITY_FAIL:
      return createCityFail(state, action);
    case actionTypes.UPDATE_CITY_START:
      return updateCityStart(state);
    case actionTypes.UPDATE_CITY_SUCCESS:
      return updateCitySuccess(state);
    case actionTypes.UPDATE_CITY_FAIL:
      return updateCityFail(state, action);
    case actionTypes.DELETE_CITY_START:
      return deleteCityStart(state);
    case actionTypes.DELETE_CITY_SUCCESS:
      return deleteCitySuccess(state);
    case actionTypes.DELETE_CITY_FAIL:
      return deleteCityFail(state, action);
    case actionTypes.RESET_CREATED_CITY:
      return resetCreatedCity(state);
    case actionTypes.FETCH_PUBLIC_CITY_OPTIONS_START:
      return fetchPublicCityOptionsStart(state);
    case actionTypes.FETCH_PUBLIC_CITY_OPTIONS_SUCCESS:
      return fetchPublicCityOptionsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_CITY_OPTIONS_FAIL:
      return fetchPublicCityOptionsFail(state, action);
    case actionTypes.FETCH_PUBLIC_TOP_NAVIGATION_ITEMS_START:
      return fetchPublicTopNavigationItemsStart(state);
    case actionTypes.FETCH_PUBLIC_TOP_NAVIGATION_ITEMS_SUCCESS:
      return fetchPublicTopNavigationItemsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_TOP_NAVIGATION_ITEMS_FAIL:
      return fetchPublicTopNavigationItemsFail(state, action);
    case actionTypes.RESET_CITY_STORE:
      return resetCityStore();
    case actionTypes.LOGOUT:
      return resetCityStore();
    default:
      return state;
  }
};

export default reducer;
