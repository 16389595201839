import * as actionTypes from './actionTypes';
import { AmenityActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Amenity, AmenityOption } from '../../domain/Amenity';
import { ListResults } from '../../common/List/List';

export type AmenityStateType = {
  amenitiesList: ListResults<Amenity> | null;
  amenitiesLoading: boolean;
  amenitiesError: HttpError;
  amenitiesListUpdateNeeded: boolean;
  amenityCreateLoading: boolean;
  amenityCreateError: HttpError;
  amenityCreateSuccess: boolean;
  createdAmenity: Amenity | null;
  amenityUpdateLoading: boolean;
  amenityUpdateError: HttpError;
  amenityUpdateSuccess: boolean;
  amenityDeleteLoading: boolean;
  amenityDeleteError: HttpError;
  amenityDeleteSuccess: boolean;
  amenityOptions: AmenityOption[] | null;
  amenityOptionsLoading: boolean;
  amenityOptionsError: HttpError;
};

export type AmenityActionType = AmenityStateType & {
  type: AmenityActionTypes;
};

export const initialState: AmenityStateType = {
  amenitiesList: null,
  amenitiesLoading: true,
  amenitiesError: null,
  amenitiesListUpdateNeeded: false,
  amenityCreateLoading: false,
  amenityCreateError: null,
  amenityCreateSuccess: false,
  createdAmenity: null,
  amenityUpdateLoading: false,
  amenityUpdateError: null,
  amenityUpdateSuccess: false,
  amenityDeleteLoading: false,
  amenityDeleteError: null,
  amenityDeleteSuccess: false,
  amenityOptions: null,
  amenityOptionsError: null,
  amenityOptionsLoading: false,
};

const fetchAmenitiesStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenitiesLoading: true,
});

const fetchAmenitiesSuccess = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenitiesList: action.amenitiesList,
  amenitiesLoading: false,
  amenitiesError: null,
  amenitiesListUpdateNeeded: false,
});

const fetchAmenitiesFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenitiesError: action.amenitiesError,
  amenitiesLoading: false,
});

const fetchAmenityOptionsStart = (
  state: AmenityStateType,
): AmenityStateType => ({
  ...state,
  amenityOptionsLoading: true,
});

const fetchAmenityOptionsSuccess = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityOptions: action.amenityOptions,
  amenityOptionsLoading: false,
  amenityOptionsError: null,
});

const fetchAmenityOptionsFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityOptionsError: action.amenityOptionsError,
  amenityOptionsLoading: false,
});

const createAmenityStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityCreateLoading: true,
});

const createAmenitySuccess = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityCreateLoading: false,
  amenityCreateError: null,
  amenityCreateSuccess: true,
  createdAmenity: action.createdAmenity,
  amenitiesListUpdateNeeded: true,
});

const createAmenityFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityCreateLoading: false,
  amenityCreateError: action.amenityCreateError,
});

const updateAmenityStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityUpdateLoading: true,
});

const updateAmenitySuccess = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityUpdateLoading: false,
  amenityUpdateError: null,
  amenityUpdateSuccess: true,
  amenitiesListUpdateNeeded: true,
});

const updateAmenityFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityUpdateLoading: false,
  amenityUpdateError: action.amenityUpdateError,
});

const deleteAmenityStart = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityDeleteLoading: true,
});

const deleteAmenitySuccess = (state: AmenityStateType): AmenityStateType => ({
  ...state,
  amenityDeleteLoading: false,
  amenityDeleteError: null,
  amenityDeleteSuccess: true,
  amenitiesListUpdateNeeded: true,
});

const deleteAmenityFail = (
  state: AmenityStateType,
  action: AmenityActionType,
): AmenityStateType => ({
  ...state,
  amenityDeleteLoading: false,
  amenityDeleteError: action.amenityDeleteError,
});

const resetAmenityStore = (state: AmenityStateType): AmenityStateType => ({
  ...initialState,
});

const completelyResetAmenityStore = (): AmenityStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AmenityActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_AMENITIES_START:
      return fetchAmenitiesStart(state);
    case actionTypes.FETCH_AMENITIES_SUCCESS:
      return fetchAmenitiesSuccess(state, action);
    case actionTypes.FETCH_AMENITIES_FAIL:
      return fetchAmenitiesFail(state, action);
    case actionTypes.CREATE_AMENITY_START:
      return createAmenityStart(state);
    case actionTypes.CREATE_AMENITY_SUCCESS:
      return createAmenitySuccess(state, action);
    case actionTypes.CREATE_AMENITY_FAIL:
      return createAmenityFail(state, action);
    case actionTypes.UPDATE_AMENITY_START:
      return updateAmenityStart(state);
    case actionTypes.UPDATE_AMENITY_SUCCESS:
      return updateAmenitySuccess(state);
    case actionTypes.UPDATE_AMENITY_FAIL:
      return updateAmenityFail(state, action);
    case actionTypes.DELETE_AMENITY_START:
      return deleteAmenityStart(state);
    case actionTypes.DELETE_AMENITY_SUCCESS:
      return deleteAmenitySuccess(state);
    case actionTypes.DELETE_AMENITY_FAIL:
      return deleteAmenityFail(state, action);
    case actionTypes.RESET_AMENITY_STORE:
      return resetAmenityStore(state);
    case actionTypes.FETCH_AMENITY_OPTIONS_START:
      return fetchAmenityOptionsStart(state);
    case actionTypes.FETCH_AMENITIES_OPTIONS_SUCCESS:
      return fetchAmenityOptionsSuccess(state, action);
    case actionTypes.FETCH_AMENITIES_OPTIONS_FAIL:
      return fetchAmenityOptionsFail(state, action);
    case actionTypes.LOGOUT:
      return completelyResetAmenityStore();
    default:
      return state;
  }
};

export default reducer;
