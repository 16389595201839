import React, { ReactNode, useEffect, useRef } from 'react';
import { HttpError } from '../../config/Axios/axios-instance';

type Props = {
  children: ReactNode;
  onSubmit: () => void;
  error: HttpError;
  className?: string;
};

export const Form = ({ children, onSubmit, error, className }: Props) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (error) {
      formRef?.current?.scrollIntoView();
      window.scrollBy(0, -60);
    }
  }, [error]);

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={() => onSubmit()}
      ref={formRef}
      className={className}
    >
      {children}
    </form>
  );
};

export default Form;
