export const FETCH_LOCATION_CONTENTS_START = 'FETCH_LOCATION_CONTENTS_START';
export const FETCH_LOCATION_CONTENTS_SUCCESS =
  'FETCH_LOCATION_CONTENTS_SUCCESS';
export const FETCH_LOCATION_CONTENTS_FAIL = 'FETCH_LOCATION_CONTENTS_FAIL';

export const CREATE_LOCATION_CONTENT_START = 'CREATE_LOCATION_CONTENT_START';
export const CREATE_LOCATION_CONTENT_SUCCESS =
  'CREATE_LOCATION_CONTENT_SUCCESS';
export const CREATE_LOCATION_CONTENT_FAIL = 'CREATE_LOCATION_CONTENT_FAIL';

export const UPDATE_LOCATION_CONTENT_START = 'UPDATE_LOCATION_CONTENT_START';
export const UPDATE_LOCATION_CONTENT_SUCCESS =
  'UPDATE_LOCATION_CONTENT_SUCCESS';
export const UPDATE_LOCATION_CONTENT_FAIL = 'UPDATE_LOCATION_CONTENT_FAIL';

export const SET_LOCATION_CONTENT_SORT_ORDER_START =
  'SET_LOCATION_CONTENT_SORT_ORDER_START';
export const SET_LOCATION_CONTENT_SORT_ORDER_SUCCESS =
  'SET_LOCATION_CONTENT_SORT_ORDER_SUCCESS';
export const SET_LOCATION_CONTENT_SORT_ORDER_FAIL =
  'SET_LOCATION_CONTENT_SORT_ORDER_FAIL';

export const DELETE_LOCATION_CONTENT_START = 'DELETE_LOCATION_CONTENT_START';
export const DELETE_LOCATION_CONTENT_SUCCESS =
  'DELETE_LOCATION_CONTENT_SUCCESS';
export const DELETE_LOCATION_CONTENT_FAIL = 'DELETE_LOCATION_CONTENT_FAIL';

export const RESET_LOCATION_CONTENT_STORE = 'RESET_LOCATION_CONTENT_STORE';

export const LOGOUT = 'LOGOUT';

export type LocationContentActionTypes =
  | typeof FETCH_LOCATION_CONTENTS_START
  | typeof FETCH_LOCATION_CONTENTS_SUCCESS
  | typeof FETCH_LOCATION_CONTENTS_FAIL
  | typeof CREATE_LOCATION_CONTENT_START
  | typeof CREATE_LOCATION_CONTENT_SUCCESS
  | typeof CREATE_LOCATION_CONTENT_FAIL
  | typeof UPDATE_LOCATION_CONTENT_START
  | typeof UPDATE_LOCATION_CONTENT_SUCCESS
  | typeof UPDATE_LOCATION_CONTENT_FAIL
  | typeof DELETE_LOCATION_CONTENT_START
  | typeof DELETE_LOCATION_CONTENT_SUCCESS
  | typeof DELETE_LOCATION_CONTENT_FAIL
  | typeof SET_LOCATION_CONTENT_SORT_ORDER_START
  | typeof SET_LOCATION_CONTENT_SORT_ORDER_SUCCESS
  | typeof SET_LOCATION_CONTENT_SORT_ORDER_FAIL
  | typeof RESET_LOCATION_CONTENT_STORE
  | typeof LOGOUT;
