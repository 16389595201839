import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createLocationGroupFail,
  createLocationGroupStart,
  createLocationGroupSuccess,
  deleteLocationGroupFail,
  deleteLocationGroupStart,
  deleteLocationGroupSuccess,
  fetchLocationGroupsFail,
  fetchLocationGroupsStart,
  fetchLocationGroupsSuccess,
  fetchLocationGroupFail,
  fetchLocationGroupStart,
  fetchLocationGroupSuccess,
  fetchPublicLocationGroupOptionsFail,
  fetchPublicLocationGroupOptionsStart,
  fetchPublicLocationGroupOptionsSuccess,
  updateLocationGroupFail,
  updateLocationGroupStart,
  updateLocationGroupSuccess,
} from './actions';
import { getFormData } from '../../utility/form/formData';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { EntityTranslation } from '../../domain/EntityTranslation';

const API_URL = '/location-groups';

export type LocationGroupCreateRequest = {
  name: string;
  description: string;
  locationIds: number[];
  image: File;
  images: File[];
  translations: EntityTranslation[];
  seo: {
    slug: string;
    title: string;
    description: string;
    translations: EntityTranslation[];
  };
};

export type LocationGroupUpdateRequest = {
  id: number;
  name: string;
  description: string;
  locationIds: number[];
  image: File;
  images: File[];
  imageFiles: number[];
  translations: EntityTranslation[];
  seo: {
    slug: string;
    title: string;
    description: string;
    translations: EntityTranslation[];
  };
};

export const fetchLocationGroups =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchLocationGroupsStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchLocationGroupsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLocationGroupsFail(err.response.data.error));
      });
  };

export const fetchLocationGroup = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchLocationGroupStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchLocationGroupSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLocationGroupFail(err.response.data.error));
    });
};

export const fetchPublicLocationGroupOptions = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicLocationGroupOptionsStart());
  return axios
    .get(`${API_URL}/options`)
    .then((response) => {
      dispatch(fetchPublicLocationGroupOptionsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicLocationGroupOptionsFail(err.response.data.error));
    });
};

export const createLocationGroup =
  (inputs: LocationGroupCreateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createLocationGroupStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then((response) => {
        dispatch(createLocationGroupSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.LOCATION_GROUP_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createLocationGroupFail(err.response.data.message));
      });
  };

export const updateLocationGroup =
  (inputs: LocationGroupUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateLocationGroupStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then((response) => {
        dispatch(updateLocationGroupSuccess());
        showToast(translate(intl, 'SUCCESS.LOCATION_GROUP_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateLocationGroupFail(err.response.data.message));
      });
  };

export const deleteLocationGroup =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteLocationGroupStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        dispatch(deleteLocationGroupSuccess());
        showToast(translate(intl, 'SUCCESS.LOCATION_GROUP_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteLocationGroupFail(err.response.data.message));
      });
  };
