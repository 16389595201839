import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './LocationSelectForm.module.scss';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import Alert from '../../../common/Alert/Alert';
import TextField from '../../../common/TextField/TextField';
import { useForm } from '../../../hooks/useForm/useForm';
import Button from '../../../common/Button/Button';
import { HttpError } from '../../../config/Axios/axios-instance';
import Form from '../../../common/Form/Form';
import FormCard from '../../../common/FormCard/FormCard';
import { getGlobalError } from '../../../utility/error/httpErrorParser';
import { Location } from '../../../domain/Location';
import * as locationService from '../../../store/location/service';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/Router/routes';
import { useIntl } from 'react-intl';
import { translate } from '../../../utility/messageTranslator/translate';

type Props = {
  isLoading?: boolean;
  error: HttpError;
  locationOptions: Location[];
  onLocationOptionsFetch: () => void;
  onLocationSelect: (locationId: number) => void;
  onSuccessSelect?: () => void;
  selectedLocation: number | null;
};

type FormInputs = {
  locationId: string;
};

export const LocationSelectForm = ({
  isLoading,
  error,
  locationOptions,
  onLocationOptionsFetch,
  onLocationSelect,
  onSuccessSelect,
  selectedLocation,
}: Props) => {
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    onLocationOptionsFetch();
  }, []);

  const locationsOptions = useMemo(
    () =>
      locationOptions.map((location: Location) => {
        return {
          value: location.id.toString(),
          label: `${location.name} - ${location.city.name}`,
        };
      }) || [],
    [locationOptions],
  );

  const INPUTS = [
    {
      name: 'locationId',
      label: translate(intl, 'LOCATION.FORM_SELECTED_LOCATION'),
      type: 'autocomplete',
      validation: [
        {
          type: 'required',
        },
      ],
      options: [],
      value: selectedLocation?.toString() ?? '',
    },
  ];

  const handleSubmit = async (submitInputs: FormInputs) => {
    onLocationSelect(Number(submitInputs.locationId));
    onSuccessSelect?.();
    navigate(routes.locations.list);
    setTimeout(() => {
      navigate(routes.programs.list);
    }, 50);
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSelectChange,
    onSetValidationErrors,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  useEffect(() => {
    if (locationsOptions) {
      setNewInputObject('locationId', {
        options: locationsOptions,
      });
    }
  }, [locationsOptions]);

  const globalError = getGlobalError(error);

  return (
    <div className={styles.locationSelectForm}>
      <Form className={styles.form} error={error} onSubmit={onSubmit}>
        <FormCard>
          {globalError && (
            <Alert variant="danger" capitalize={false}>
              {globalError}
            </Alert>
          )}
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              type={input.type}
              options={input.options}
              onSelectChange={onSelectChange}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {translate(intl, 'LOCATION.FORM_SELECT_LOCATION')}
          </Button>
        </FormCard>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.location.locationOptionsError,
  isLoading: state.location.locationOptionsLoading,
  locationOptions: state.location.locationOptions,
  selectedLocation: state.location.selectedLocation,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLocationSelect: (locationId: number) =>
    dispatch(locationService.selectNewLocation(locationId)),
  onLocationOptionsFetch: () =>
    dispatch(locationService.fetchLocationOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelectForm);
