import { ChangeEvent, useEffect, useState } from 'react';
import { parseInputValue } from '../../utility/inputParser/inputParser';
import { SelectChangeEvent } from '@mui/material';

export const MAX_PAGINATION_LIMIT = 999999;

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export type SortBy = string | null;
export type SortDirection = typeof SORT_ASC | typeof SORT_DESC;

export const DEFAULT_PAGE = 0;
export const DEFAULT_LIMIT = 10;
export const MAX_LIMIT = 1000;

export const TIMEOUT_BETWEEN_FETCH = 300;

export const DEFAULT_LIST_PARAMS = {
  search: '',
  sortBy: null,
  sortDirection: SORT_ASC,
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
} as ListParams;

export const MAX_LIST_PARAMS = {
  search: '',
  sortBy: null,
  sortDirection: SORT_ASC,
  page: DEFAULT_PAGE,
  limit: MAX_LIMIT,
} as ListParams;

export type ListParams = {
  search: string;
  sortBy: string | null;
  sortDirection: SortDirection;
  page: number;
  limit: number;
};

export const useList = (
  isUpdateNeeded: boolean,
  onFetch: (params: ListParams) => void,
  defaultParams?: ListParams,
  allowSortingFromOutside?: boolean,
  allowDateChangeFromOutside?: boolean,
  allowTableParamChangeFromOutside?: boolean,
) => {
  const [tableParams, setTableParams] = useState<ListParams>({
    ...DEFAULT_LIST_PARAMS,
    ...defaultParams,
  });

  if (allowSortingFromOutside) {
    useEffect(() => {
      if (defaultParams) {
        onSort(
          defaultParams.sortBy as SortBy,
          defaultParams.sortDirection as SortDirection,
        );
      }
    }, [defaultParams]);
  }

  if (allowDateChangeFromOutside) {
    useEffect(() => {
      if (defaultParams) {
        setTableParams((prevState) => ({
          ...prevState,
        }));
      }
    }, [defaultParams]);
  }

  if (allowTableParamChangeFromOutside) {
    useEffect(() => {
      if (defaultParams) {
        setTableParams((prevState) => ({
          ...prevState,
          ...defaultParams,
        }));
      }
    }, [defaultParams]);
  }

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      onFetch({
        ...tableParams,
      });
    }, TIMEOUT_BETWEEN_FETCH);

    return () => clearTimeout(timeoutRef);
  }, [tableParams]);

  useEffect(() => {
    isUpdateNeeded && onFetch(tableParams);
  }, [isUpdateNeeded, tableParams]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
    setTableParams((prevState) => ({
      ...prevState,
      page: DEFAULT_PAGE,
      search: parseInputValue(event),
    }));

  const onCategoriesChange = (event: SelectChangeEvent) =>
    setTableParams((prevState) => {
      const {
        target: { value },
      } = event;
      return {
        ...prevState,
        page: DEFAULT_PAGE,
        categoryIds: typeof value === 'string' ? value.split(',') : value,
      };
    });

  const onSearchSubmit = () =>
    setTableParams((prevState) => ({
      ...prevState,
      page: DEFAULT_PAGE,
    }));

  const onSort = (sortBy: SortBy, sortDirection: SortDirection) =>
    setTableParams((prevState) => ({
      ...prevState,
      sortBy,
      sortDirection,
      page: DEFAULT_PAGE,
    }));

  const onPageChange = (event: any | null, page: number) => {
    event?.preventDefault();
    setTableParams((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const onLimitChange = (event: any) => {
    event.preventDefault();
    setTableParams((prevState) => ({
      ...prevState,
      limit: event.target.value,
      page: DEFAULT_PAGE,
    }));
  };

  return {
    tableParams,
    onSearchChange,
    onSearchSubmit,
    onSort,
    onPageChange,
    onLimitChange,
    onCategoriesChange,
  };
};
