export const FETCH_TRANSFERS_START = 'FETCH_TRANSFERS_START';
export const FETCH_TRANSFERS_SUCCESS = 'FETCH_TRANSFERS_SUCCESS';
export const FETCH_TRANSFERS_FAIL = 'FETCH_TRANSFERS_FAIL';

export const CONTACT_START = 'CONTACT_START';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_FAIL = 'CONTACT_FAIL';

export const RESET_TRANSFER_STORE = 'RESET_TRANSFER_STORE';

export const LOGOUT = 'LOGOUT';

export type TransferActionTypes =
  | typeof FETCH_TRANSFERS_START
  | typeof FETCH_TRANSFERS_SUCCESS
  | typeof FETCH_TRANSFERS_FAIL
  | typeof CONTACT_START
  | typeof CONTACT_SUCCESS
  | typeof CONTACT_FAIL
  | typeof RESET_TRANSFER_STORE
  | typeof LOGOUT;
