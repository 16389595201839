import { Locale } from '../../domain/Translation';
import { Language } from '../../domain/Language';

export const getTranslationByKey = (
  entity: string,
  entityId: number,
  locale: Locale,
  property: string,
  languages: Language[],
) => {
  const language = languages.find((language) => language.locale === locale);

  if (!language) {
    return null;
  }

  return language.entityTranslations.find(
    (translation) =>
      translation.entityId === entityId &&
      translation.property === property &&
      translation.entity === entity,
  )?.value;
};
