import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createLocationFail,
  createLocationStart,
  createLocationSuccess,
  deleteLocationFail,
  deleteLocationStart,
  deleteLocationSuccess,
  fetchLocationsFail,
  fetchLocationsStart,
  fetchLocationsSuccess,
  fetchLocationFail,
  fetchLocationStart,
  fetchLocationSuccess,
  updateLocationFail,
  updateLocationStart,
  updateLocationSuccess,
  fetchLocationOptionsStart,
  fetchLocationOptionsSuccess,
  fetchLocationOptionsFail,
  selectLocation,
  searchLocationsStart,
  searchLocationsSuccess,
  searchLocationsFail,
  fetchPublicLocationStart,
  fetchPublicLocationSuccess,
  fetchPublicLocationFail,
  fetchLocationSearchFilterStart,
  fetchLocationSearchFilterSuccess,
  fetchLocationSearchFilterFail,
  fetchLocationForSearchStart,
  fetchLocationForSearchSuccess,
  fetchLocationForSearchFail,
  setLocationSortOrdersStart,
  setLocationSortOrdersSuccess,
  setLocationSortOrdersFail,
  fetchLocationGalleryStart,
  fetchLocationGallerySuccess,
  fetchLocationGalleryFail,
} from './actions';
import { getFormData } from '../../utility/form/formData';
import { resetRoomStore } from '../room/actions';
import { resetAgeStore } from '../age/actions';
import { resetProgramStore, resetPublicProgramStore } from '../program/actions';
import { resetPeriodStore } from '../period/actions';
import { resetProgramPriceStore } from '../programPrice/actions';
import { resetProgramDiscountStore } from '../programDiscount/actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { LocationStars } from '../../domain/Location';
import { EntityTranslation } from '../../domain/EntityTranslation';

const API_URL = '/locations';

export type LocationCreateRequest = {
  name: string;
  description: string;
  image: File;
  images: File[];
  cityId: number;
  locationGroupId: number | null;
  seo: {
    slug: string;
    title: string;
    description: string;
    translations: EntityTranslation[];
  };
  amenityIds: number[];
  stars: LocationStars;
  translations: EntityTranslation[];
};

export type LocationUpdateRequest = {
  id: number;
  name: string;
  description: string;
  image: File;
  images: File[];
  imageFiles: number[];
  cityId: number;
  locationGroupId: number | null;
  seo: {
    slug: string;
    title: string;
    description: string;
    translations: EntityTranslation[];
  };
  amenityIds: number[];
  stars: LocationStars;
  translations: EntityTranslation[];
};

export type LocationsSearchParams = {
  cityId?: string;
  locationGroupId?: string;
  adults?: string;
  children?: string[];
  arrival?: string;
  departure?: string;
  catering?: string[];
  stars?: string[];
  amenity?: string[];
};

export type LocationSortOrderModel = {
  locationId: number;
  sortOrder: number;
};

export const fetchLocations = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchLocationsStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchLocationsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLocationsFail(err.response.data.error));
    });
};

export const fetchLocationSearchFilter = () => (dispatch: Dispatch) => {
  dispatch(fetchLocationSearchFilterStart());
  return axios
    .get(`${API_URL}/filters`)
    .then((response) => {
      dispatch(fetchLocationSearchFilterSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLocationSearchFilterFail(err.response.data.error));
    });
};

export const searchLocations =
  (params: LocationsSearchParams) => (dispatch: Dispatch) => {
    dispatch(searchLocationsStart());
    dispatch(resetPublicProgramStore());
    return axios
      .get(`${API_URL}/search`, { params })
      .then((response) => {
        dispatch(searchLocationsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(searchLocationsFail(err.response.data.error));
      });
  };

export const fetchLocationForSearch =
  (id: number, params: LocationsSearchParams) => (dispatch: Dispatch) => {
    dispatch(fetchLocationForSearchStart());
    return axios
      .get(`${API_URL}/${id}/search`, { params })
      .then((response) => {
        dispatch(fetchLocationForSearchSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLocationForSearchFail(err.response.data.error));
      });
  };

export const fetchLocation = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchLocationStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchLocationSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLocationFail(err.response.data.error));
    });
};

export const fetchPublicLocation =
  (locationSlug: string) => (dispatch: Dispatch) => {
    dispatch(fetchPublicLocationStart());
    dispatch(resetPublicProgramStore());
    return axios
      .get(`${API_URL}/${locationSlug}/public`)
      .then((response) => {
        dispatch(fetchPublicLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicLocationFail(err.response.data.error));
      });
  };

export const fetchLocationGallery =
  (locationId: number) => (dispatch: Dispatch) => {
    dispatch(fetchLocationGalleryStart());
    return axios
      .get(`${API_URL}/${locationId}/gallery`)
      .then((response) => {
        dispatch(fetchLocationGallerySuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLocationGalleryFail(err.response.data.error));
      });
  };

export const fetchLocationOptions = () => (dispatch: Dispatch) => {
  dispatch(fetchLocationOptionsStart());
  return axios
    .get(`${API_URL}/all`)
    .then((response) => {
      dispatch(fetchLocationOptionsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchLocationOptionsFail(err.response.data.error));
    });
};

export const createLocation =
  (inputs: LocationCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createLocationStart());
    return axios
      .post(API_URL, getFormData(inputs))
      .then((response) => {
        dispatch(createLocationSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.LOCATION_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createLocationFail(err.response.data.message));
      });
  };

export const updateLocation =
  (inputs: LocationUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateLocationStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then((response) => {
        dispatch(updateLocationSuccess());
        showToast(translate(intl, 'SUCCESS.LOCATION_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateLocationFail(err.response.data.message));
      });
  };

export const deleteLocation =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteLocationStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then((response) => {
        dispatch(deleteLocationSuccess());
        showToast(translate(intl, 'SUCCESS.LOCATION_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteLocationFail(err.response.data.message));
      });
  };

export const updateLocationSortOrder =
  (inputs: LocationSortOrderModel[], intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(setLocationSortOrdersStart());
    return axios
      .patch(`${API_URL}/set-sort-orders`, inputs)
      .then((response) => {
        dispatch(setLocationSortOrdersSuccess());
        showToast(translate(intl, 'SUCCESS.LOCATION_SORT_ORDER'), 'success');
      })
      .catch((err) => {
        dispatch(setLocationSortOrdersFail(err.response.data.message));
      });
  };

export const selectNewLocation =
  (locationId: number) => (dispatch: Dispatch) => {
    dispatch(selectLocation(locationId));
    dispatch(resetRoomStore());
    dispatch(resetAgeStore());
    dispatch(resetProgramStore());
    dispatch(resetPeriodStore());
    dispatch(resetProgramPriceStore());
    dispatch(resetProgramDiscountStore());
  };
