import * as actionTypes from './actionTypes';
import { WeekdayActionTypes } from './actionTypes';
import { Weekday } from '../../domain/Weekday';

export type WeekdayStateType = {
  weekdays: Weekday[] | null;
  weekdaysLoading: boolean;
};

export type UserActionType = WeekdayStateType & {
  type: WeekdayActionTypes;
};

export const initialState: WeekdayStateType = {
  weekdays: null,
  weekdaysLoading: false,
};

const fetchWeekdaysStart = (state: WeekdayStateType): WeekdayStateType => ({
  ...state,
  weekdaysLoading: true,
});

const fetchWeekdaysSuccess = (
  state: WeekdayStateType,
  action: UserActionType,
): WeekdayStateType => ({
  ...state,
  weekdays: action.weekdays,
  weekdaysLoading: false,
});

const fetchWeekdaysFail = (state: WeekdayStateType): WeekdayStateType => ({
  ...state,
  weekdaysLoading: false,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_WEEKDAY_START:
      return fetchWeekdaysStart(state);
    case actionTypes.FETCH_WEEKDAY_SUCCESS:
      return fetchWeekdaysSuccess(state, action);
    case actionTypes.FETCH_WEEKDAY_FAIL:
      return fetchWeekdaysFail(state);
    default:
      return state;
  }
};

export default reducer;
