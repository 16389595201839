import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Period } from '../../domain/Period';

export const createPeriodStart = () => ({
  type: actionTypes.CREATE_PERIOD_START,
});

export const createPeriodSuccess = (createdPeriod: Period) => ({
  type: actionTypes.CREATE_PERIOD_SUCCESS,
  createdPeriod,
});

export const createPeriodFail = (periodCreateError: HttpError) => ({
  type: actionTypes.CREATE_PERIOD_FAIL,
  periodCreateError,
});

export const duplicatePeriodStart = () => ({
  type: actionTypes.DUPLICATE_PERIOD_START,
});

export const duplicatePeriodSuccess = (duplicatedPeriod: Period) => ({
  type: actionTypes.DUPLICATE_PERIOD_SUCCESS,
  duplicatedPeriod,
});

export const duplicatePeriodFail = (periodDuplicateError: HttpError) => ({
  type: actionTypes.DUPLICATE_PERIOD_FAIL,
  periodDuplicateError,
});

export const updatePeriodStart = () => ({
  type: actionTypes.UPDATE_PERIOD_START,
});

export const updatePeriodSuccess = (updatedPeriod: Period) => ({
  type: actionTypes.UPDATE_PERIOD_SUCCESS,
  updatedPeriod,
});

export const updatePeriodFail = (periodUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_PERIOD_FAIL,
  periodUpdateError,
});

export const deletePeriodStart = () => ({
  type: actionTypes.DELETE_PERIOD_START,
});

export const deletePeriodSuccess = () => ({
  type: actionTypes.DELETE_PERIOD_SUCCESS,
});

export const deletePeriodFail = (periodDeleteError: HttpError) => ({
  type: actionTypes.DELETE_PERIOD_FAIL,
  periodDeleteError,
});

export const resetCreatedPeriod = () => ({
  type: actionTypes.RESET_CREATED_PERIOD,
});

export const resetDuplicatedPeriod = () => ({
  type: actionTypes.RESET_DUPLICATED_PERIOD,
});

export const resetUpdatedPeriod = () => ({
  type: actionTypes.RESET_UPDATED_PERIOD,
});

export const resetDeletedPeriod = () => ({
  type: actionTypes.RESET_DELETED_PERIOD,
});

export const resetPeriodStore = () => ({
  type: actionTypes.RESET_PERIOD_STORE,
});
