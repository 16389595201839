import React, { useState } from 'react';
import { TopNavigationItem, NavigationItem } from '../../Layout/Layout';
import { NavLink, Link } from 'react-router-dom';
import styles from '../PublicNavigation.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

export type Props = {
  topNavigationItem: TopNavigationItem;
  handleNavigationLinkClick: (label: string) => void;
};

export const MobilePublicNavigation = ({
  topNavigationItem,
  handleNavigationLinkClick,
}: Props) => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const [activeSecondSubMenuIndex, setActiveSecondSubMenuIndex] = useState(-1);

  const handleMenuClick = (event: React.MouseEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    setSubMenuVisible(!isSubMenuVisible);
    setActiveSecondSubMenuIndex(-1);
  };

  const handleSubMenuButtonClick = (event: React.MouseEvent, index: number) => {
    event?.preventDefault();
    setActiveSecondSubMenuIndex(
      activeSecondSubMenuIndex === index ? -1 : index,
    );
  };

  const renderLocationsWithoutGroups = (locations: NavigationItem[]) => {
    return (
      locations && (
        <div className={styles.mobileNavigationLocationsWithoutGroupsContainer}>
          {locations.map((location) => (
            <Link
              key={`mobile-${location.label}`}
              to={location.to}
              className={styles.navigationItem}
              onClick={() => handleNavigationLinkClick(location.label)}
            >
              {location.label}
            </Link>
          ))}
        </div>
      )
    );
  };

  return (
    <div className={styles.mobileNavigationItemContainer}>
      <NavLink
        key={`mobile-${topNavigationItem.label}`}
        to={topNavigationItem.to}
        className={({ isActive }) =>
          cx(styles.navigationItem, {
            [styles.activeSubItem]: isActive,
          })
        }
        onClick={() => handleNavigationLinkClick(topNavigationItem.label)}
      >
        {topNavigationItem.label}

        <span
          className={styles.openSubMenuButton}
          onClick={(event) => {
            handleMenuClick(event);
          }}
        >
          <FontAwesomeIcon
            className={styles.openSubMenuIcon}
            icon={isSubMenuVisible ? faChevronUp : faChevronDown}
          />
        </span>
      </NavLink>

      {isSubMenuVisible && (
        <>
          {topNavigationItem?.locationGroups &&
            topNavigationItem.locationGroups.map((locationGroup, index) => (
              <div
                key={`mobile-${locationGroup.label}`}
                className={styles.mobileNavigationLocationGroupContainer}
                onClick={(event) => handleSubMenuButtonClick(event, index)}
              >
                <div className={styles.mobileNavigationSubMenuTitleContainer}>
                  {locationGroup.label}
                  <span
                    className={styles.openSubMenuButton}
                    onClick={(event) => handleSubMenuButtonClick(event, index)}
                  >
                    <FontAwesomeIcon
                      className={styles.openSubMenuIcon}
                      icon={
                        activeSecondSubMenuIndex === index
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </span>
                </div>

                {activeSecondSubMenuIndex === index &&
                  locationGroup.locations.map((location) => (
                    <Link
                      key={`mobile-${location.label}`}
                      to={location.to}
                      className={styles.navigationItem}
                      onClick={() => handleNavigationLinkClick(location.label)}
                    >
                      {location.label}
                    </Link>
                  ))}
              </div>
            ))}

          {topNavigationItem?.locationsWithoutGroup && (
            <>
              {renderLocationsWithoutGroups(
                topNavigationItem.locationsWithoutGroup,
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MobilePublicNavigation;
