export const FETCH_AMENITIES_START = 'FETCH_AMENITIES_START';
export const FETCH_AMENITIES_SUCCESS = 'FETCH_AMENITIES_SUCCESS';
export const FETCH_AMENITIES_FAIL = 'FETCH_AMENITIES_FAIL';

export const FETCH_AMENITY_OPTIONS_START = 'FETCH_AMENITY_OPTIONS_START';
export const FETCH_AMENITIES_OPTIONS_SUCCESS =
  'FETCH_AMENITIES_OPTIONS_SUCCESS';
export const FETCH_AMENITIES_OPTIONS_FAIL = 'FETCH_AMENITIES_OPTIONS_FAIL';

export const CREATE_AMENITY_START = 'CREATE_AMENITY_START';
export const CREATE_AMENITY_SUCCESS = 'CREATE_AMENITY_SUCCESS';
export const CREATE_AMENITY_FAIL = 'CREATE_AMENITY_FAIL';

export const UPDATE_AMENITY_START = 'UPDATE_AMENITY_START';
export const UPDATE_AMENITY_SUCCESS = 'UPDATE_AMENITY_SUCCESS';
export const UPDATE_AMENITY_FAIL = 'UPDATE_AMENITY_FAIL';

export const DELETE_AMENITY_START = 'DELETE_AMENITY_START';
export const DELETE_AMENITY_SUCCESS = 'DELETE_AMENITY_SUCCESS';
export const DELETE_AMENITY_FAIL = 'DELETE_AMENITY_FAIL';

export const RESET_AMENITY_STORE = 'RESET_AMENITY_STORE';

export const LOGOUT = 'LOGOUT';

export type AmenityActionTypes =
  | typeof FETCH_AMENITIES_START
  | typeof FETCH_AMENITIES_SUCCESS
  | typeof FETCH_AMENITIES_FAIL
  | typeof CREATE_AMENITY_START
  | typeof CREATE_AMENITY_SUCCESS
  | typeof CREATE_AMENITY_FAIL
  | typeof UPDATE_AMENITY_START
  | typeof UPDATE_AMENITY_SUCCESS
  | typeof UPDATE_AMENITY_FAIL
  | typeof DELETE_AMENITY_START
  | typeof DELETE_AMENITY_SUCCESS
  | typeof DELETE_AMENITY_FAIL
  | typeof FETCH_AMENITY_OPTIONS_START
  | typeof FETCH_AMENITIES_OPTIONS_SUCCESS
  | typeof FETCH_AMENITIES_OPTIONS_FAIL
  | typeof RESET_AMENITY_STORE
  | typeof LOGOUT;
