import * as actionTypes from './actionTypes';
import { ProgramActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Program } from '../../domain/Program';
import { ListResults } from '../../common/List/List';
import { Period } from '../../domain/Period';
import _ from 'lodash';
import { NightsPeriod } from '../../domain/NightsPeriod';
import { ProgramParams } from './service';

export type ProgramStateType = {
  programsList: ListResults<Program> | null;
  programsLoading: boolean;
  programsError: HttpError;
  programsListUpdateNeeded: boolean;
  programCreateLoading: boolean;
  programCreateError: HttpError;
  programCreateSuccess: boolean;
  createdProgram: Program | null;
  updatedProgram: Program | null;
  programUpdateLoading: boolean;
  programUpdateError: HttpError;
  programUpdateSuccess: boolean;
  programDeleteLoading: boolean;
  programDeleteError: HttpError;
  programDeleteSuccess: boolean;
  program: Program | null;
  programLoading: boolean;
  programError: HttpError;
  publicPrograms: { publicProgram: Program; programParams: ProgramParams }[];
  publicProgramLoading: boolean;
  publicProgramError: HttpError;
  programSortOrdersLoading: boolean;
  programSortOrdersError: HttpError;
  programSortOrdersSuccess: boolean;
};

export type ProgramActionType = ProgramStateType & {
  type: ProgramActionTypes;
  period: Period;
  nightsPeriod: NightsPeriod;
  publicProgram: { publicProgram: Program; programParams: ProgramParams };
};

export const initialState: ProgramStateType = {
  programsList: null,
  programsLoading: true,
  programsError: null,
  programsListUpdateNeeded: false,
  programCreateLoading: false,
  programCreateError: null,
  programCreateSuccess: false,
  createdProgram: null,
  updatedProgram: null,
  programUpdateLoading: false,
  programUpdateError: null,
  programUpdateSuccess: false,
  programDeleteLoading: false,
  programDeleteError: null,
  programDeleteSuccess: false,
  program: null,
  programLoading: false,
  programError: null,
  publicPrograms: [],
  publicProgramLoading: false,
  publicProgramError: null,
  programSortOrdersLoading: false,
  programSortOrdersError: null,
  programSortOrdersSuccess: false,
};

const fetchProgramsStart = (state: ProgramStateType): ProgramStateType => ({
  ...state,
  programsLoading: true,
});

const fetchProgramsSuccess = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  programsList: action.programsList,
  programsLoading: false,
  programsError: null,
  programsListUpdateNeeded: false,
});

const fetchProgramsFail = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  programsError: action.programsError,
  programsLoading: false,
});

const fetchProgramStart = (state: ProgramStateType): ProgramStateType => ({
  ...state,
  programLoading: true,
  programCreateSuccess: false,
  programCreateError: null,
});

const fetchProgramSuccess = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  program: action.program,
  programLoading: false,
  programError: null,
});

const fetchProgramFail = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  programError: action.programError,
  programLoading: false,
});

const fetchPublicProgramStart = (
  state: ProgramStateType,
): ProgramStateType => ({
  ...state,
  publicProgramLoading: true,
});

const fetchPublicProgramSuccess = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  publicPrograms: [...state.publicPrograms, action.publicProgram],
  publicProgramLoading: false,
  publicProgramError: null,
});

const fetchPublicProgramFail = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  publicProgramError: action.publicProgramError,
  publicProgramLoading: false,
});

const createProgramStart = (state: ProgramStateType): ProgramStateType => ({
  ...state,
  programCreateLoading: true,
});

const createProgramSuccess = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  programCreateLoading: false,
  programCreateError: null,
  programCreateSuccess: true,
  createdProgram: action.createdProgram,
});

const createProgramFail = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  programCreateLoading: false,
  programCreateError: action.programCreateError,
});

const updateProgramStart = (state: ProgramStateType): ProgramStateType => ({
  ...state,
  programUpdateLoading: true,
  programUpdateSuccess: false,
});

const updateProgramSuccess = (state: ProgramStateType): ProgramStateType => ({
  ...state,
  programUpdateLoading: false,
  programUpdateError: null,
  programUpdateSuccess: true,
});

const updateProgramFail = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  programUpdateLoading: false,
  programUpdateError: action.programUpdateError,
});

const deleteProgramStart = (state: ProgramStateType): ProgramStateType => ({
  ...state,
  programDeleteLoading: true,
});

const deleteProgramSuccess = (state: ProgramStateType): ProgramStateType => ({
  ...state,
  programDeleteLoading: false,
  programDeleteError: null,
  programDeleteSuccess: true,
  programsListUpdateNeeded: true,
});

const deleteProgramFail = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  programDeleteLoading: false,
  programDeleteError: action.programDeleteError,
});

const appendProgramPeriods = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  program: state.program
    ? {
        ...state.program,
        periods: _.sortBy(
          [...(state.program?.periods ?? []), action.period],
          (period) => period.dateFrom,
        ),
      }
    : null,
});

const appendProgramNightsPeriods = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  program: state.program
    ? {
        ...state.program,
        nights: _.sortBy(
          [...(state.program?.nights ?? []), action.nightsPeriod],
          (period) => period.minNights,
        ),
      }
    : null,
});

const setProgramSortOrdersStart = (
  state: ProgramStateType,
): ProgramStateType => ({
  ...state,
  programSortOrdersLoading: true,
  programSortOrdersSuccess: false,
});

const setProgramSortOrdersSuccess = (
  state: ProgramStateType,
): ProgramStateType => ({
  ...state,
  programSortOrdersLoading: false,
  programSortOrdersError: null,
  programsListUpdateNeeded: true,
  programSortOrdersSuccess: true,
});

const setProgramSortOrdersFail = (
  state: ProgramStateType,
  action: ProgramActionType,
): ProgramStateType => ({
  ...state,
  programSortOrdersError: action.programSortOrdersError,
  programSortOrdersSuccess: false,
  programSortOrdersLoading: false,
});

const resetCreatedProgram = (state: ProgramStateType): ProgramStateType => ({
  ...state,
  createdProgram: null,
});

const resetProgramStore = (): ProgramStateType => ({
  ...initialState,
});

const resetPublicProgramStore = (
  state: ProgramStateType,
): ProgramStateType => ({
  ...state,
  publicPrograms: [],
  publicProgramLoading: false,
  publicProgramError: null,
});

const completelyResetProgramStore = (): ProgramStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProgramActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PROGRAMS_START:
      return fetchProgramsStart(state);
    case actionTypes.FETCH_PROGRAMS_SUCCESS:
      return fetchProgramsSuccess(state, action);
    case actionTypes.FETCH_PROGRAMS_FAIL:
      return fetchProgramsFail(state, action);
    case actionTypes.FETCH_PROGRAM_START:
      return fetchProgramStart(state);
    case actionTypes.FETCH_PROGRAM_SUCCESS:
      return fetchProgramSuccess(state, action);
    case actionTypes.FETCH_PROGRAM_FAIL:
      return fetchProgramFail(state, action);
    case actionTypes.FETCH_PUBLIC_PROGRAM_START:
      return fetchPublicProgramStart(state);
    case actionTypes.FETCH_PUBLIC_PROGRAM_SUCCESS:
      return fetchPublicProgramSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_PROGRAM_FAIL:
      return fetchPublicProgramFail(state, action);
    case actionTypes.CREATE_PROGRAM_START:
      return createProgramStart(state);
    case actionTypes.CREATE_PROGRAM_SUCCESS:
      return createProgramSuccess(state, action);
    case actionTypes.CREATE_PROGRAM_FAIL:
      return createProgramFail(state, action);
    case actionTypes.UPDATE_PROGRAM_START:
      return updateProgramStart(state);
    case actionTypes.UPDATE_PROGRAM_SUCCESS:
      return updateProgramSuccess(state);
    case actionTypes.UPDATE_PROGRAM_FAIL:
      return updateProgramFail(state, action);
    case actionTypes.DELETE_PROGRAM_START:
      return deleteProgramStart(state);
    case actionTypes.DELETE_PROGRAM_SUCCESS:
      return deleteProgramSuccess(state);
    case actionTypes.DELETE_PROGRAM_FAIL:
      return deleteProgramFail(state, action);
    case actionTypes.APPEND_PROGRAM_PERIODS:
      return appendProgramPeriods(state, action);
    case actionTypes.APPEND_PROGRAM_NIGHTS_PERIODS:
      return appendProgramNightsPeriods(state, action);
    case actionTypes.SET_PROGRAM_SORT_ORDER_START:
      return setProgramSortOrdersStart(state);
    case actionTypes.SET_PROGRAM_SORT_ORDER_SUCCESS:
      return setProgramSortOrdersSuccess(state);
    case actionTypes.SET_PROGRAM_SORT_ORDER_FAIL:
      return setProgramSortOrdersFail(state, action);
    case actionTypes.RESET_CREATED_PROGRAM:
      return resetCreatedProgram(state);
    case actionTypes.RESET_PROGRAM_STORE:
      return resetProgramStore();
    case actionTypes.RESET_PUBLIC_PROGRAM_STORE:
      return resetPublicProgramStore(state);
    case actionTypes.LOGOUT:
      return completelyResetProgramStore();
    default:
      return state;
  }
};

export default reducer;
