export const SET_PROGRAM_PRICE_START = 'SET_PROGRAM_PRICE_START';
export const SET_PROGRAM_PRICE_SUCCESS = 'SET_PROGRAM_PRICE_SUCCESS';
export const SET_PROGRAM_PRICE_FAIL = 'SET_PROGRAM_PRICE_FAIL';

export const RESET_SET_PROGRAM_PRICE = 'RESET_SET_PROGRAM_PRICE';

export const RESET_PROGRAM_PRICE_STORE = 'RESET_PROGRAM_PRICE_STORE';

export const LOGOUT = 'LOGOUT';

export type ProgramPriceActionTypes =
  | typeof SET_PROGRAM_PRICE_START
  | typeof SET_PROGRAM_PRICE_SUCCESS
  | typeof SET_PROGRAM_PRICE_FAIL
  | typeof RESET_SET_PROGRAM_PRICE
  | typeof RESET_PROGRAM_PRICE_STORE
  | typeof LOGOUT;
