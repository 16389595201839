import * as actionTypes from './actionTypes';
import { AgeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Age } from '../../domain/Age';
import { ListResults } from '../../common/List/List';

export type AgeStateType = {
  agesList: ListResults<Age> | null;
  agesLoading: boolean;
  agesError: HttpError;
  agesListUpdateNeeded: boolean;
  ageCreateLoading: boolean;
  ageCreateError: HttpError;
  ageCreateSuccess: boolean;
  createdAge: Age | null;
  ageUpdateLoading: boolean;
  ageUpdateError: HttpError;
  ageUpdateSuccess: boolean;
  ageDeleteLoading: boolean;
  ageDeleteError: HttpError;
  ageDeleteSuccess: boolean;
  age: Age | null;
  ageLoading: boolean;
  ageError: HttpError;
};

export type AgeActionType = AgeStateType & {
  type: AgeActionTypes;
};

export const initialState: AgeStateType = {
  agesList: null,
  agesLoading: true,
  agesError: null,
  agesListUpdateNeeded: false,
  ageCreateLoading: false,
  ageCreateError: null,
  ageCreateSuccess: false,
  createdAge: null,
  ageUpdateLoading: false,
  ageUpdateError: null,
  ageUpdateSuccess: false,
  ageDeleteLoading: false,
  ageDeleteError: null,
  ageDeleteSuccess: false,
  age: null,
  ageLoading: false,
  ageError: null,
};

const fetchAgesStart = (state: AgeStateType): AgeStateType => ({
  ...state,
  agesLoading: true,
});

const fetchAgesSuccess = (
  state: AgeStateType,
  action: AgeActionType,
): AgeStateType => ({
  ...state,
  agesList: action.agesList,
  agesLoading: false,
  agesError: null,
  agesListUpdateNeeded: false,
});

const fetchAgesFail = (
  state: AgeStateType,
  action: AgeActionType,
): AgeStateType => ({
  ...state,
  agesError: action.agesError,
  agesLoading: false,
});

const fetchAgeStart = (state: AgeStateType): AgeStateType => ({
  ...state,
  ageLoading: true,
  ageCreateSuccess: false,
  ageCreateError: null,
});

const fetchAgeSuccess = (
  state: AgeStateType,
  action: AgeActionType,
): AgeStateType => ({
  ...state,
  age: action.age,
  ageLoading: false,
  ageError: null,
});

const fetchAgeFail = (
  state: AgeStateType,
  action: AgeActionType,
): AgeStateType => ({
  ...state,
  ageError: action.ageError,
  ageLoading: false,
});

const createAgeStart = (state: AgeStateType): AgeStateType => ({
  ...state,
  ageCreateLoading: true,
});

const createAgeSuccess = (
  state: AgeStateType,
  action: AgeActionType,
): AgeStateType => ({
  ...state,
  ageCreateLoading: false,
  ageCreateError: null,
  ageCreateSuccess: true,
  createdAge: action.createdAge,
});

const createAgeFail = (
  state: AgeStateType,
  action: AgeActionType,
): AgeStateType => ({
  ...state,
  ageCreateLoading: false,
  ageCreateError: action.ageCreateError,
});

const updateAgeStart = (state: AgeStateType): AgeStateType => ({
  ...state,
  ageUpdateLoading: true,
  ageUpdateSuccess: false,
});

const updateAgeSuccess = (state: AgeStateType): AgeStateType => ({
  ...state,
  ageUpdateLoading: false,
  ageUpdateError: null,
  ageUpdateSuccess: true,
});

const updateAgeFail = (
  state: AgeStateType,
  action: AgeActionType,
): AgeStateType => ({
  ...state,
  ageUpdateLoading: false,
  ageUpdateError: action.ageUpdateError,
});

const deleteAgeStart = (state: AgeStateType): AgeStateType => ({
  ...state,
  ageDeleteLoading: true,
});

const deleteAgeSuccess = (state: AgeStateType): AgeStateType => ({
  ...state,
  ageDeleteLoading: false,
  ageDeleteError: null,
  ageDeleteSuccess: true,
  agesListUpdateNeeded: true,
});

const deleteAgeFail = (
  state: AgeStateType,
  action: AgeActionType,
): AgeStateType => ({
  ...state,
  ageDeleteLoading: false,
  ageDeleteError: action.ageDeleteError,
});

const resetCreatedAge = (state: AgeStateType): AgeStateType => ({
  ...state,
  createdAge: null,
});

const resetAgeStore = (): AgeStateType => ({
  ...initialState,
});

const completelyResetAgeStore = (): AgeStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AgeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_AGES_START:
      return fetchAgesStart(state);
    case actionTypes.FETCH_AGES_SUCCESS:
      return fetchAgesSuccess(state, action);
    case actionTypes.FETCH_AGES_FAIL:
      return fetchAgesFail(state, action);
    case actionTypes.FETCH_AGE_START:
      return fetchAgeStart(state);
    case actionTypes.FETCH_AGE_SUCCESS:
      return fetchAgeSuccess(state, action);
    case actionTypes.FETCH_AGE_FAIL:
      return fetchAgeFail(state, action);
    case actionTypes.CREATE_AGE_START:
      return createAgeStart(state);
    case actionTypes.CREATE_AGE_SUCCESS:
      return createAgeSuccess(state, action);
    case actionTypes.CREATE_AGE_FAIL:
      return createAgeFail(state, action);
    case actionTypes.UPDATE_AGE_START:
      return updateAgeStart(state);
    case actionTypes.UPDATE_AGE_SUCCESS:
      return updateAgeSuccess(state);
    case actionTypes.UPDATE_AGE_FAIL:
      return updateAgeFail(state, action);
    case actionTypes.DELETE_AGE_START:
      return deleteAgeStart(state);
    case actionTypes.DELETE_AGE_SUCCESS:
      return deleteAgeSuccess(state);
    case actionTypes.DELETE_AGE_FAIL:
      return deleteAgeFail(state, action);
    case actionTypes.RESET_CREATED_AGE:
      return resetCreatedAge(state);
    case actionTypes.RESET_AGE_STORE:
      return resetAgeStore();
    case actionTypes.LOGOUT:
      return completelyResetAgeStore();
    default:
      return state;
  }
};

export default reducer;
