import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';
import { User } from '../../domain/User';

export type UserStateType = {
  currentUser: User | null;
  currentUserLoading: boolean;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
};

export const initialState: UserStateType = {
  currentUser: null,
  currentUserLoading: false,
};

const fetchCurrentUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: true,
});

const fetchCurrentUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const resetUserStore = () => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ME_START:
      return fetchCurrentUserStart(state);
    case actionTypes.FETCH_ME_SUCCESS:
      return fetchCurrentUserSuccess(state, action);
    case actionTypes.FETCH_ME_FAIL:
      return fetchCurrentUserFail(state);
    case actionTypes.LOGOUT:
      return resetUserStore();
    default:
      return state;
  }
};

export default reducer;
