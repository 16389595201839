export const FETCH_AGES_START = 'FETCH_AGES_START';
export const FETCH_AGES_SUCCESS = 'FETCH_AGES_SUCCESS';
export const FETCH_AGES_FAIL = 'FETCH_AGES_FAIL';

export const FETCH_AGE_START = 'FETCH_AGE_START';
export const FETCH_AGE_SUCCESS = 'FETCH_AGE_SUCCESS';
export const FETCH_AGE_FAIL = 'FETCH_AGE_FAIL';

export const CREATE_AGE_START = 'CREATE_AGE_START';
export const CREATE_AGE_SUCCESS = 'CREATE_AGE_SUCCESS';
export const CREATE_AGE_FAIL = 'CREATE_AGE_FAIL';

export const UPDATE_AGE_START = 'UPDATE_AGE_START';
export const UPDATE_AGE_SUCCESS = 'UPDATE_AGE_SUCCESS';
export const UPDATE_AGE_FAIL = 'UPDATE_AGE_FAIL';

export const DELETE_AGE_START = 'DELETE_AGE_START';
export const DELETE_AGE_SUCCESS = 'DELETE_AGE_SUCCESS';
export const DELETE_AGE_FAIL = 'DELETE_AGE_FAIL';

export const RESET_CREATED_AGE = 'RESET_CREATED_AGE';

export const RESET_AGE_STORE = 'RESET_AGE_STORE';

export const LOGOUT = 'LOGOUT';

export type AgeActionTypes =
  | typeof FETCH_AGES_START
  | typeof FETCH_AGES_SUCCESS
  | typeof FETCH_AGES_FAIL
  | typeof FETCH_AGE_START
  | typeof FETCH_AGE_SUCCESS
  | typeof FETCH_AGE_FAIL
  | typeof CREATE_AGE_START
  | typeof CREATE_AGE_SUCCESS
  | typeof CREATE_AGE_FAIL
  | typeof UPDATE_AGE_START
  | typeof UPDATE_AGE_SUCCESS
  | typeof UPDATE_AGE_FAIL
  | typeof DELETE_AGE_START
  | typeof DELETE_AGE_SUCCESS
  | typeof DELETE_AGE_FAIL
  | typeof RESET_CREATED_AGE
  | typeof RESET_AGE_STORE
  | typeof LOGOUT;
