export const FETCH_LOCATION_GROUPS_START = 'FETCH_LOCATION_GROUPS_START';
export const FETCH_LOCATION_GROUPS_SUCCESS = 'FETCH_LOCATION_GROUPS_SUCCESS';
export const FETCH_LOCATION_GROUPS_FAIL = 'FETCH_LOCATION_GROUPS_FAIL';

export const FETCH_LOCATION_GROUP_START = 'FETCH_LOCATION_GROUP_START';
export const FETCH_LOCATION_GROUP_SUCCESS = 'FETCH_LOCATION_GROUP_SUCCESS';
export const FETCH_LOCATION_GROUP_FAIL = 'FETCH_LOCATION_GROUP_FAIL';

export const CREATE_LOCATION_GROUP_START = 'CREATE_LOCATION_GROUP_START';
export const CREATE_LOCATION_GROUP_SUCCESS = 'CREATE_LOCATION_GROUP_SUCCESS';
export const CREATE_LOCATION_GROUP_FAIL = 'CREATE_LOCATION_GROUP_FAIL';

export const UPDATE_LOCATION_GROUP_START = 'UPDATE_LOCATION_GROUP_START';
export const UPDATE_LOCATION_GROUP_SUCCESS = 'UPDATE_LOCATION_GROUP_SUCCESS';
export const UPDATE_LOCATION_GROUP_FAIL = 'UPDATE_LOCATION_GROUP_FAIL';

export const DELETE_LOCATION_GROUP_START = 'DELETE_LOCATION_GROUP_START';
export const DELETE_LOCATION_GROUP_SUCCESS = 'DELETE_LOCATION_GROUP_SUCCESS';
export const DELETE_LOCATION_GROUP_FAIL = 'DELETE_LOCATION_GROUP_FAIL';

export const FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_START =
  'FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_START';
export const FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_SUCCESS =
  'FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_SUCCESS';
export const FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_FAIL =
  'FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_FAIL';

export const RESET_CREATED_LOCATION_GROUP = 'RESET_CREATED_LOCATION_GROUP';

export const RESET_LOCATION_GROUP_STORE = 'RESET_LOCATION_GROUP_STORE';

export const LOGOUT = 'LOGOUT';

export type LocationGroupActionTypes =
  | typeof FETCH_LOCATION_GROUPS_START
  | typeof FETCH_LOCATION_GROUPS_SUCCESS
  | typeof FETCH_LOCATION_GROUPS_FAIL
  | typeof FETCH_LOCATION_GROUP_START
  | typeof FETCH_LOCATION_GROUP_SUCCESS
  | typeof FETCH_LOCATION_GROUP_FAIL
  | typeof CREATE_LOCATION_GROUP_START
  | typeof CREATE_LOCATION_GROUP_SUCCESS
  | typeof CREATE_LOCATION_GROUP_FAIL
  | typeof UPDATE_LOCATION_GROUP_START
  | typeof UPDATE_LOCATION_GROUP_SUCCESS
  | typeof UPDATE_LOCATION_GROUP_FAIL
  | typeof DELETE_LOCATION_GROUP_START
  | typeof DELETE_LOCATION_GROUP_SUCCESS
  | typeof DELETE_LOCATION_GROUP_FAIL
  | typeof FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_START
  | typeof FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_SUCCESS
  | typeof FETCH_PUBLIC_LOCATION_GROUP_OPTIONS_FAIL
  | typeof RESET_CREATED_LOCATION_GROUP
  | typeof RESET_LOCATION_GROUP_STORE
  | typeof LOGOUT;
